import React, { Component } from 'react'
import Heading from '../../components/Heading'
import CartItem from './CartItem'
import { Button } from 'react-bootstrap'
import EmptyCart from '../../assets/images/empty_cart.png'
import CartService from '../../axios/cartService'
import { toast } from 'react-toastify'
import UserService from '../../axios/userService'
import { Modal } from 'react-bootstrap'

class Cart extends Component {
  constructor (props) {
    super(props)
    this.state = {
      address: '',
      coupon: '',
      orderId: '',
      pay: 'paypal',
      showProfile: false,
      profile: {}
    }
    this.setAddress = this.setAddress.bind(this)
    this.setCoupon = this.setCoupon.bind(this)
    this.checkout = this.checkout.bind(this)
    this.changePay = this.changePay.bind(this)
    this.handleChangeAddress = this.handleChangeAddress.bind(this)
  }
  componentDidMount = () => {
    this.getMyProfile()
  }

  setAddress = val => {
    this.setState({ address: val })
  }
  setCoupon = val => {
    this.setState({ coupon: val })
  }
  changePay = _pay => {
    this.setState({ pay: _pay, orderId: '' })
  }
  checkout = () => {
    let _products = this.props.cart?.products.map(e => {
      return {
        id: e.id,
        color: e.color,
        count: e.count
      }
    })
    let payload = {
      payment_method: this.state.pay,
      address:
        this.state.address ||
        JSON.parse(localStorage.getItem('auth'))?.user?.address,
      coupon: this.state.coupon,
      products: _products
    }
    let token = JSON.parse(localStorage.getItem('auth'))?.token
    if (
      JSON.parse(localStorage.getItem('auth'))
        ?.user?.roles.map(e => e.name)
        .includes('User')
    ) {
      CartService.CheckOut(payload, token)
        .then(resp => {
          console.log('res', resp)
          if (!!resp?.link && this.state.pay === 'paypal')
            window.open(resp?.link, '_blank')
          else this.setState({ orderId: resp.id })
          this.props.clearCart()
          this.props.lang.lang == 'en'
            ? toast.success('Order has been submitted successfully')
            : toast.success('تم استلام طلبك بنجاح')
        })
        .catch(error =>
          this.props.lang.lang == 'en'
            ? toast.error('An Error Occurred')
            : toast.error('لقد حدث خطأ ما')
        )
    } else toast.error("You don't have permissions to process this operation")
  }

  handleChangeAddress = e => {
    this.setState({
      address: e.target.value
    })
  }
  pay = () => {
    UserService.payStribe(this.state.orderId)
      .then(resp => {
        console.log(resp)
        if (resp.success) {
          window.open(resp.data['0'], '_blank')
        }
      })
      .catch(er => console.error(er))
  }

  getMyProfile = () => {
    UserService.myProfile()
      .then(resp => {
        this.setState({ profile: resp[0] })
      })
      .catch(er => console.error(er))
  }

  saveMyProfile = () => {
    let payload = {
      name: this.state.profile.name,
      email: this.state.profile.email,
      phone: this.state.profile.phone,
      city: this.state.profile.city,
      role: this.state.profile.role,
      address: this.state.profile.address,
      birthday: this.state.profile.birthday
    }
    var data = new FormData()
    Object.keys(payload).forEach(e => {
      data.append(e, payload[e])
    })
    UserService.editUser(data).then(resp => {
      this.checkout()
    })
  }

  showLoginHideModal = () => {
    this.setState({ showProfile: !this.state.showProfile })
  }

  profileModal = ({ showLoginHideModal, saveClicked, lang, show }) => {
    return (
      <Modal
        show={show}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        id='loginModal'
        className='modal fade login'
      >
        <Modal.Header
          className='d-flex justify-content-center'
          style={{ textAlign: 'center' }}
        >
          <div className='modal--close--button' onClick={showLoginHideModal}>
            <i className='fas fa-times'></i>
          </div>
          {lang === 'en'
            ? 'The order will be delivered to the following information'
            : lang === 'gr'
            ? 'Die Bestellung wird an folgende Adresse geliefert:'
            : 'سيتم تسليم الطلب إلى المعلومات التالية'}
        </Modal.Header>
        <Modal.Body>
          <div className='cold-md-6'>
            <form>
              <div class='form-group'>
                <label
                  for='exampleInputPassword1'
                  style={
                    this.props.lang.lang === 'en' ||
                    this.props.lang.lang === 'gr'
                      ? { width: '100%', textAlign: 'left' }
                      : { width: '100%', textAlign: 'right' }
                  }
                >
                  {this.props.lang.lang === 'en'
                    ? 'City'
                    : this.props.lang.lang === 'gr'
                    ? 'Stadt'
                    : 'المدينة'}
                </label>
                <input
                  type='text'
                  value={this.state.profile.city}
                  onChange={event => {
                    this.setState({
                      profile: {
                        ...this.state.profile,
                        city: event.target.value
                      }
                    })
                  }}
                  class='form-control'
                  id='exampleInputPassword1'
                  placeholder='City'
                />
              </div>
              <div class='form-group'>
                <label
                  for='exampleInputPassword1'
                  style={
                    this.props.lang.lang === 'en' ||
                    this.props.lang.lang === 'gr'
                      ? { width: '100%', textAlign: 'left' }
                      : { width: '100%', textAlign: 'right' }
                  }
                >
                  {this.props.lang.lang === 'en'
                    ? 'Address'
                    : this.props.lang.lang === 'gr'
                    ? 'Adresse'
                    : 'العنوان'}
                </label>
                <input
                  type='text'
                  value={this.state.profile.address}
                  onChange={event => {
                    this.setState({
                      profile: {
                        ...this.state.profile,
                        address: event.target.value
                      }
                    })
                  }}
                  class='form-control'
                  id='exampleInputPassword1'
                  placeholder='Address'
                />
              </div>
              <div class='form-group'>
                <label
                  for='exampleInputPassword1'
                  style={
                    this.props.lang.lang === 'en' ||
                    this.props.lang.lang === 'gr'
                      ? { width: '100%', textAlign: 'left' }
                      : { width: '100%', textAlign: 'right' }
                  }
                >
                  {this.props.lang.lang === 'en'
                    ? 'Phone'
                    : this.props.lang.lang === 'gr'
                    ? 'Telefon'
                    : 'رقم الهاتف'}
                </label>
                <input
                  type='text'
                  value={this.state.profile.phone}
                  onChange={event => {
                    this.setState({
                      profile: {
                        ...this.state.profile,
                        phone: event.target.value
                      }
                    })
                  }}
                  class='form-control'
                  id='exampleInputPassword1'
                />
              </div>
              <div class='form-group'>
                <label
                  for='exampleInputPassword1'
                  style={
                    this.props.lang.lang === 'en' ||
                    this.props.lang.lang === 'gr'
                      ? { width: '100%', textAlign: 'left' }
                      : { width: '100%', textAlign: 'right' }
                  }
                >
                  {this.props.lang.lang === 'en'
                    ? 'Postal Code'
                    : this.props.lang.lang === 'gr'
                    ? 'Postal Code'
                    : 'رمز البريد'}
                </label>
                <input
                  type='text'
                  value={this.state.profile.postal_code}
                  onChange={event => {
                    this.setState({
                      profile: {
                        ...this.state.profile,
                        postal_code: event.target.value
                      }
                    })
                  }}
                  class='form-control'
                  id='exampleInputPassword1'
                  placeholder={
                    this.props.lang.lang === 'en'
                      ? 'Postal Code'
                      : this.props.lang.lang === 'gr'
                      ? 'Postal Code'
                      : 'رمز البريد'
                  }
                />
              </div>
              <button
                type='button'
                onClick={this.saveMyProfile}
                class='btn btn-primary w-100'
              >
                {this.props.lang.lang === 'en'
                    ? 'Save'
                    : this.props.lang.lang === 'gr'
                    ? 'Speichern'
                    : 'حفظ'}
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  render () {
    console.log('state', this.props.cart)
    const products = this.props.cart?.products
    const total = this.props.cart?.total
    return (
      <div className='shopping--cart' data-aos='fade-up'>
        <div className='container'>
          <div className='shopping--cart--container'>
            <div className='row '>
              <Heading
                title={
                  this.props.lang.lang == 'en'
                    ? 'Your Shopping Cart'
                    : this.props.lang.lang == 'gr'
                    ? 'Ihr Warenkorb'
                    : 'سلة المنتجات الخاصة بك'
                }
                data-aos='fade-up'
              />
            </div>
            <div style={{ height: 30 }}></div>
            <CartItem
              currency={this.props.currency.currency}
              lang={this.props.lang.lang}
              items={products || {}}
              handleClick={this.props.postCart}
              clearItem={this.props.clearItem}
            />
            {products !== undefined && products !== null ? (
              <div
                className='d-flex flex-column justify-content-start align-items-start'
                style={{ paddingRight: 50 }}
              >
                <h3 style={{ textAlign: 'center' }}>
                  {this.props.lang.lang === 'en' && ` Total `}
                  {this.props.lang.lang === 'gr' && ` Gesamt `}
                  <span style={{ color: '#FE4C50' }}>{total}</span>
                  {this.props.lang.lang === 'ar' && ` المجموع `}
                </h3>
                <hr />
                <div class='row w-100'>
                  <div class='col-md-6'>
                    <input
                      class='checkbox m-2'
                      checked={this.state.pay === 'paypal'}
                      name='pay'
                      type='radio'
                      value='paypal'
                      onChange={e => this.changePay('paypal')}
                    />
                    <i
                      class='fa fa-cc-paypal'
                      style={{ fontSize: '40px', color: 'blue' }}
                    ></i>
                  </div>
                  <div class='col-md-6'>
                    <input
                      class='checkbox m-2'
                      name='pay'
                      type='radio'
                      checked={this.state.pay === 'stripe'}
                      onChange={e => this.changePay('stripe')}
                      value='stripe'
                    />
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='120'
                      height='60'
                      fill-rule='evenodd'
                      fill='#6772e5'
                    >
                      <path d='M101.547 30.94c0-5.885-2.85-10.53-8.3-10.53-5.47 0-8.782 4.644-8.782 10.483 0 6.92 3.908 10.414 9.517 10.414 2.736 0 4.805-.62 6.368-1.494v-4.598c-1.563.782-3.356 1.264-5.632 1.264-2.23 0-4.207-.782-4.46-3.494h11.24c0-.3.046-1.494.046-2.046zM90.2 28.757c0-2.598 1.586-3.678 3.035-3.678 1.402 0 2.897 1.08 2.897 3.678zm-14.597-8.345c-2.253 0-3.7 1.057-4.506 1.793l-.3-1.425H65.73v26.805l5.747-1.218.023-6.506c.828.598 2.046 1.448 4.07 1.448 4.115 0 7.862-3.3 7.862-10.598-.023-6.667-3.816-10.3-7.84-10.3zm-1.38 15.84c-1.356 0-2.16-.483-2.713-1.08l-.023-8.53c.598-.667 1.425-1.126 2.736-1.126 2.092 0 3.54 2.345 3.54 5.356 0 3.08-1.425 5.38-3.54 5.38zm-16.4-17.196l5.77-1.24V13.15l-5.77 1.218zm0 1.747h5.77v20.115h-5.77zm-6.185 1.7l-.368-1.7h-4.966V40.92h5.747V27.286c1.356-1.77 3.655-1.448 4.368-1.195v-5.287c-.736-.276-3.425-.782-4.782 1.7zm-11.494-6.7L34.535 17l-.023 18.414c0 3.402 2.552 5.908 5.954 5.908 1.885 0 3.264-.345 4.023-.76v-4.667c-.736.3-4.368 1.356-4.368-2.046V25.7h4.368v-4.897h-4.37zm-15.54 10.828c0-.897.736-1.24 1.954-1.24a12.85 12.85 0 0 1 5.7 1.47V21.47c-1.908-.76-3.793-1.057-5.7-1.057-4.667 0-7.77 2.437-7.77 6.506 0 6.345 8.736 5.333 8.736 8.07 0 1.057-.92 1.402-2.207 1.402-1.908 0-4.345-.782-6.276-1.84v5.47c2.138.92 4.3 1.3 6.276 1.3 4.782 0 8.07-2.368 8.07-6.483-.023-6.85-8.782-5.632-8.782-8.207z' />
                    </svg>
                  </div>
                </div>
                <div className='row d-flex justify-content-center w-100'>
                  <Button
                    onClick={this.showLoginHideModal}
                    variant='danger'
                    style={{ marginTop: 30, width: '50%' }}
                    size='sm'
                  >
                    {this.props.lang.lang === 'en'
                      ? `Confirm Checkout `
                      : this.props.lang.lang === 'gr'
                      ? 'Bestellung bestätigen'
                      : ' تأكيد '}
                  </Button>
                  {!!this.state.orderId && this.state.pay === 'stripe' && (
                    <Button
                      onClick={this.pay}
                      variant='danger'
                      size='sm'
                      style={{ marginTop: 30 }}
                    >
                      {this.props.lang.lang === 'en'
                        ? ` Confirm Payment`
                        : this.props.lang.lang == 'en'
                        ? 'Zahlung bestätigen'
                        : 'تأكيد الدفع'}
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <img
                  src={EmptyCart}
                  alt=''
                  style={{ maxHeight: 400, maxWidth: 400 }}
                  className='img-fluid'
                />
              </div>
            )}
          </div>
        </div>
        {this.profileModal({
          showLoginHideModal: this.showLoginHideModal,
          saveClicked: this.saveClicked,
          show: this.state.showProfile,
          lang: this.props.lang.lang
        })}
      </div>
    )
  }
}

export default Cart
