import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

// Layout Types
import BaseLayout from '../layouts/BaseLayout'

// Route Views
import Home from '../views/Home/HomeContainer'
import SingleProductContainer from '../views/Product/SingleProductContainer'
import CategoryContainer from '../views/Category/CategoryContainer'
import WishListContainer from '../views/Wishlist/WishListContainer'
import PolicyContainer from '../views/Policy/PolicyContainer'
import ProfileGoogleContainer from '../views/ProfileGoogle/ProfileGoogleContainer'

var routes = [
  {
    path: '/',
    exact: true,
    layout: BaseLayout,
    component: Home
  },
  {
    path: '/home',
    layout: BaseLayout,
    component: () => <Redirect to='/' />
  },
  {
    path: '/single-product/:id',
    layout: BaseLayout,
    component: SingleProductContainer
  },
  {
    path: '/categories',
    layout: BaseLayout,
    component: CategoryContainer
  },
  {
    path: '/wishlist',
    layout: BaseLayout,
    component: WishListContainer
  },
  {
    path: '/products',
    layout: BaseLayout,
    component: CategoryContainer
  },
  {
    path: '/policy',
    layout: BaseLayout,
    component: PolicyContainer
  },
  {
    path: '/profile-google',
    layout: BaseLayout,
    component: ProfileGoogleContainer
  }
]

export default routes
