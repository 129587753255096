import React, { Component } from 'react'
import { login } from '../../ServerRequest'
import API from '../../axios/API'
import Auth from '../../modules/Auth'
import HomeBanner from '../../components/HomeBanner'
import CategoryBanner from '../../components/CategoryBanner/CategoryBanner'
import NewArrivals from '../../components/Products/NewArrivals'
import BestSeller from '../../components/Products/BestSeller'
import Benefit from '../../components/Benefit'
import Advertisement from '../../components/Advertisement'
import PropTypes from 'prop-types'
import jumpTo from '../../modules/Navigation'
import LoginRegister from '../../components/LoginRegisterModal'
import { toast } from 'react-toastify'

class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: null,
      modalShow: false,
      login: true,
      lang: props.lang.lang,
      currency: props.currency.currency
    }
    this.addToBag = this.addToBag.bind(this)
  }

  componentDidMount () {
    if (!this.props.newArrivalProducts) {
      this.props.getNewArrivalProducts()
    }
  }

  showHideModal = () => {
    this.setState({ modalShow: false })
  }

  loginClicked = () => {
    this.setState({ modalShow: true, login: true })
  }
  registerClicked = () => {
    this.setState({ modalShow: true, login: false })
  }

  addToBag = (id, count, color, price, image, name) => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      if (color == '')
        this.props.lang.lang === 'en'
            ? toast.success('Product added to cart successfully')
            : this.props.lang.lang === 'gr'
            ? toast.success('Produkt erfolgreich zum Warenkorb hinzugefügt')
            : toast.success('تم إضافة المنتج بنجاح إلى السلة')
      else {
        let cart = this.props.postCart(id, count, color, price, image, name)
        this.props.lang.lang == 'en'
          ? toast.success('Product added to cart successfully')
          : toast.success('تم إضافة المنتج بنجاح إلى السلة')
      }
    } else {
      this.setState({ modalShow: true })
    }
  }

  render () {
    console.log('this.props', this.props)
    const { newArrivalProducts } = this.props
    return (
      <div>
        <HomeBanner lang={this.props.lang.lang} />
        <CategoryBanner lang={this.props.lang.lang} />
        {newArrivalProducts ? (
          <NewArrivals
            currency={this.props.currency.currency}
            products={newArrivalProducts}
            addToBag={this.addToBag}
            lang={this.props.lang.lang}
          />
        ) : null}
        <Benefit />
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    )
  }
}

export default Home
