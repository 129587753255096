import api from './API'
import Auth from '../modules/Auth'
import { toast } from 'react-toastify'

export default class UserService {
  static async myProfile () {
    return api
      .get('/users')
      .then(resp => {
        return resp
      })
      .catch(error => {
        console.log('Api Error:', error)
        throw error
      })
  }
  static async editUser (payload) {
    let id = Auth.getUserDetails().id
    return api
      .post('/users/edit/' + id, payload)
      .then(resp => {
        if (resp.data.success) {
          toast.success(resp.data.message)
        }
        return resp.data.data
      })
      .catch(error => {})
  }
  static async myOrder () {
    return api
      .get('/my-orders')
      .then(resp => {
        return resp.data
      })
      .catch(error => {})
  }

  static async sendComplaint (payload) {
    return api
      .post('/complaint', payload)
      .then(resp => {
        return resp.data
      })
      .catch(error => {})
  }

  static async payStribe (id) {
    return api
      .post('/processPayment/' + id)
      .then(resp => {
        return resp.data
      })
      .catch(error => {})
  }

  static async payPaybal (id, amount) {
    let payload = {
      amount: amount,
      payment_method: 'paypal',
      notes: ''
    }
    return api
      .post('orders/' + id + '/add-payment', payload)
      .then(resp => {
        return resp.data
      })
      .catch(error => {})
  }
}
