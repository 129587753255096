import axios from "axios";
import https from "https";
import { AxiosRequestConfig } from "axios/index";
import { toast } from "react-toastify";
import Auth from "../modules/Auth";

const token =
  Auth.getUserDetails() !== undefined &&
  Auth.getUserDetails() !== null &&
  Auth.getToken() !== undefined
    ? Auth.getToken()
    : "";
const _headers = {
  Authorization: `Bearer ${token}`,
};
const configs = {
  baseURL: `https://chelloshal-deutschland.de/api`,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
  headers: _headers,
};
const instance = axios.create(configs);
instance.defaults.headers.common["Content-Type"] =
  "application/json;charset=utf-8";

instance.interceptors.response.use(
  (response) => {
    console.log(response);
   
    if (!response.data.success) {
      let result = response.data;
      return result;
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      toast("You're unauthorized to do this!", {
        type: "error",
      });
    } else if (error?.response?.status === 403) {
      toast("Session expired! Please login again.", {
        type: "error",
      });
    } else if (error?.response?.status === 422) {
      console.log(error?.response);
      toast(error?.response.data.message, {
        type: "error",
      });
      throw error;
    } else {
      if (!error?.response?.data?.success) {
        localStorage.setItem("loginError", JSON.stringify(error.response.data));
        let result = error.response.data;
        toast(result.message, {
          type: "error",
        });
        throw error;
      }
    }
  }
);

export default instance;
