import React, { Component } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

class Filter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      categories: this.props.categories,
      active: this.props.active
    }
    this.setActive = this.setActive.bind(this)
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({
        categories: this.props.categories,
        active: this.props.active
      })
    }
  }
  setActive (id) {
    this.props.filterByCategory(id)
  }
  render () {
    console.log(this.state)
    return (
      <div class='sidebar_section' style={{ zIndex: '1' }}>
        <div class='sidebar_title'>
          <h5>
            {this.props.lang === 'en'
              ? `Categories`
              : this.props.lang === 'gr'
              ? 'Kategorien'
              : `الأصناف`}
          </h5>
        </div>
        <ul class='sidebar_categories'>
          {this.state.active === -1 && (
            <li className='active'>
              {(this.props.lang === 'en' || this.props.lang === 'gr') && (
                <span>
                  <i class='fa fa-angle-double-right' aria-hidden='true'></i>
                </span>
              )}
              <Link href='javascript:void(0)'>
                {this.props.lang === 'en'
                  ? `All`
                  : this.props.lang === 'gr'
                  ? 'Alle'
                  : `الكل`}
              </Link>
              {this.props.lang === 'ar' && (
                <span>
                  <i
                    class='fa fa-angle-double-left'
                    style={{ marginLeft: '10px' }}
                    aria-hidden='true'
                  ></i>
                </span>
              )}
            </li>
          )}
          {this.state.active !== -1 && (
            <li>
              <a href='javascript:void(0)' onClick={() => this.setActive(-1)}>
                {(this.props.lang === 'en' || this.props.lang === 'gr') && (
                  <>                    
                    {this.props.lang === 'en'
                      ? `All`
                      : this.props.lang === 'gr'
                      ? 'Alle'
                      : `الكل`}
                  </>
                )}
              </a>
            </li>
          )}
          {this.state.categories &&
            this.state.categories.map((cat, index) => {
              if (this.state.active === cat.id)
                return (
                  <li className='active'>
                    {(this.props.lang === 'en' || this.props.lang === 'gr') && (
                      <span>
                        <i
                          class='fa fa-angle-double-right'
                          aria-hidden='true'
                        ></i>
                      </span>
                    )}
                    <a href='javascript:void(0)'>
                      {this.props.lang === 'en'
                        ? `${cat.name} (${cat.product_count})`
                        : this.props.lang === 'gr'
                        ? `${cat.name_de} (${cat.product_count})`
                        : `${cat.name_a} (${cat.product_count})`}
                    </a>
                    {this.props.lang == 'ar' && (
                      <span>
                        <i
                          class='fa fa-angle-double-left'
                          style={{ marginLeft: '10px' }}
                          aria-hidden='true'
                        ></i>
                      </span>
                    )}
                  </li>
                )
              else
                return (
                  <li>
                    <Link
                      href='javascript:void(0)'
                      onClick={() => this.setActive(cat.id)}
                    >
                      {this.props.lang === 'en'
                        ? `${cat.name} (${cat.product_count})`
                        : this.props.lang === 'gr'
                        ? `${cat.name_de} (${cat.product_count})`
                        : `${cat.name_a} (${cat.product_count})`}
                    </Link>
                  </li>
                )
            })}
        </ul>
      </div>
    )
  }
}

export default Filter
