import api from './API'

export default class ProductService {
  static async GetNewArriveProducts () {
    return api
      .get('/newarrive')
      .then(resp => {
        if (resp.data.success) return resp.data.data
      })
      .catch(error => {
        console.log('Api Error:', error)
        throw error
      })
  }
  static async GetFavList () {
    return api
      .get('/wichlist')
      .then(resp => {
        if (resp.data.success) return resp.data.data
      })
      .catch(error => {
        console.log('Api Error:', error)
        throw error
      })
  }
  static async GetProduct (id) {
    return api
      .get('/products/' + id)
      .then(resp => {
        if (resp.data.success) return resp.data.data
      })
      .catch(error => {
        console.log('Api Error:', error)
        throw error
      })
  }
  static async addToFav (id) {
    return api
      .post('/favorite/' + id)
      .then(resp => {
        if (resp.data.success) return resp.data.data
      })
      .catch(error => {
        console.log('Api Error:', error)
        throw error
      })
  }
  static async getColors () {
    return api.get('/colors').then(response => {
      if (response && response.data) {
        console.log(response)
        return response.data
      }
    })
  }

  static async getAllProducts() {
    return api
    .get('/products')
    .then(resp => {
      if (resp.data.success) return resp.data.data;
    })
    .catch(error => {
      console.log('Api Error:', error)
      throw error
    })
  }
  
  static async showWishlist () {
    return api
      .get('/wichlist')
      .then(resp => {
        if (resp.data.success) return resp.data.data
      })
      .catch(error => {
        console.log('Api Error:', error)
        throw error
      })
  }
  static async deleteFromFav (id) {
    return api
      .delete('/favorite/' + id)
      .then(resp => {
        if (resp.data.success) return resp.data.data
      })
      .catch(error => {
        console.log('Api Error:', error)
        throw error
      })
  }
}
