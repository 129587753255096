import {
  connect
} from "react-redux";
import SingleProduct from "./SingleProduct";
import {
  getProduct
} from "../../redux/actions/productAction";
import {
  getColors
} from "../../redux/actions/productAction";
import {
  getVariantsByProductId
} from "../../redux/actions/variantsAction";
import {
  postCart
} from "../../redux/actions/cartAction";

const mapStoreToProps = (state) => ({
  product: state.product.product,
  variants: state.variant.variants,
  currency: state.currency,
  colors: state.product.colors,
  lang: state.lang
});
const mapDispatchToProps = {
  getProduct,
  getColors,
  getVariantsByProductId,
  postCart,
};

export default connect(mapStoreToProps, mapDispatchToProps)(SingleProduct);