import React, { Component } from 'react'
import { BASE_URL } from '../../base'
import jumpTo from "../../modules/Navigation";

function CartItem ({ items, handleClick, clearItem, lang, currency }) {
  return (
    <div style={{ marginTop: 30 }}>
      {items !== undefined &&
        items !== null &&
        Object.keys(items).map(id => (
          <div
            className='row shopping--cart--item'
            style={
              lang === 'en' || lang === 'gr'
                ? { direction: 'ltr', border: '1px solid beige' }
                : { direction: 'rtl', border: '1px solid beige' }
            }
            data-aos='fade-up'
          >
            <div className='col-sm-2'>
              <div className='cart--item--img'>
                <img
                  onClick={() => jumpTo(`/single-product/${items[id].id}`)}
                  style={{ cursor: 'pointer' }}
                  src={`${items[id].image}`}
                  alt=''
                  className='img-fluid'
                />
              </div>
            </div>
            <div className='col-sm-5'>
              <div
                style={
                  lang == 'ar'
                    ? {
                        justifyContent: 'start',
                        direction: 'rtl',
                        display: 'flex',
                        marginTop: 10,
                        marginBottom: 10
                      }
                    : { direction: 'ltr', marginTop: 10, marginBottom: 10 }
                }
                className='basket--item--title'
              >
                <span>{items[id].name}</span>
              </div>
              <div
                className='basket--item--quantity'
                style={
                  lang === 'ar'
                    ? {
                        justifyContent: 'start',
                        direction: 'rtl',
                        display: 'flex',
                        marginTop: 10,
                        marginBottom: 10
                      }
                    : { direction: 'ltr', marginTop: 10, marginBottom: 10 }
                }
              >
                <span>{lang === 'en' ? '  Quantity: ' : lang === 'gr' ? '  Menge: ' : ' الكمية: '}</span>
                <span style={{ fontWeight: 'bold', margin: '0 10px 0 10px' }}>
                  {items[id].count}
                </span>
              </div>
              <div
                className='basket--item--quantity'
                style={
                  lang == 'ar'
                    ? {
                        justifyContent: 'start',
                        direction: 'rtl',
                        display: 'flex',
                        marginTop: 10,
                        marginBottom: 10
                      }
                    : { direction: 'ltr', marginTop: 10, marginBottom: 10 }
                }
              >
                <span>{lang === 'en' ? ' Color: ' :  lang === 'gr' ? '  Farbe: ' : ' اللون: '}</span>
                <span
                  className='ml-2'
                  style={{
                    width: '20px',
                    height: '20px',
                    display: 'inline-block',
                    borderRadius: '20px',
                    margin: '0 10px 0 10px',
                    backgroundColor: items[id].color,
                    border: '1px solid black'
                  }}
                ></span>
              </div>
              <div
                className='basket--item--price'
                style={
                  lang === 'ar'
                    ? {
                        justifyContent: 'start',
                        direction: 'rtl',
                        display: 'flex',
                        marginTop: 10,
                        marginBottom: 10
                      }
                    : { direction: 'ltr', marginTop: 10, marginBottom: 10 }
                }
              >
                <span>{lang === 'en' ? ' Price: ' : lang === 'gr' ? ' Preis: ' : ' السعر: '}</span>
                <span style={{ margin: '0 10px 0 10px', fontWeight: 'bold' }}>
                  {`$${items[id].price}`}
                </span>
              </div>
              <button
                className='btn btn-danger'
                onClick={() => clearItem(items[id].id)}
              >
                <div style={{ color: '#ffffff' }}>
                  {lang === 'en' ? `remove from cart` : lang === 'gr' ? `aus dem Warenkorb entfernen` : `حذف من السلة`}
                </div>
              </button>
            </div>
          </div>
        ))}
    </div>
  )
}

export default CartItem
