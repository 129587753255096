import React, { Component } from 'react'
import Heading from '../../components/Heading'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import LoginService from '../../axios/LoginService'
import Auth from '../../modules/Auth'
import jumpTo from '../../modules/Navigation'

class ProfileGoogle extends Component {
  constructor (props) {
    super(props)
    this.state = {
      profile: {},
      subjects: [],
      compImgStyle: {
        display: 'none'
      },
      selectedFile: '',
      message: '',
      subject: '',
      file: null
    }
    this.saveMyGoogleProfile = this.saveMyGoogleProfile.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount = () => {
    this.getMyGoogleProfile()
  }

  handleChange = e => {
    this.setState({
      selectedFile: e.target.files[0],
      file: URL.createObjectURL(e.target.files[0]),
      compImgStyle: {
        marginTop: '1em',
        display: 'inline-block'
      }
    })
  }

  getMyGoogleProfile = () => {
    if (Auth.getGoogleUserDetails() !== {} && !!Auth.getGoogleUserDetails().sub)
      this.setState({
        profile: Auth.getGoogleUserDetails()
      })
    else jumpTo('/')
  }

  saveMyGoogleProfile = () => {
    if (!this.state.profile.email) {
      this.props.lang.lang === 'en'
        ? toast.error('Email are required')
        : this.props.lang.lang === 'gr'
        ? toast.error('Email ist erforderlich')
        : toast.error('الايميل الالكتروني مطلوب')
      return
    }
    if (!this.state.profile.name) {
      this.props.lang.lang === 'en'
        ? toast.error('Name is required')
        : this.props.lang.lang === 'gr'
        ? toast.error('Name ist erforderlich')
        : toast.error('الاسم مطلوب')
      return
    }
    if (!this.state.profile.password || !this.state.profile.v_password) {
      this.props.lang.lang === 'en'
        ? toast.error('Password and confirmation are required')
        : this.props.lang.lang === 'gr'
        ? toast.error('Passwort und Bestätigung sind erforderlich')
        : toast.error('يجب إدخال كلمة المرور مع التأكيد')
      return
    }
    if (this.state.profile.password !== this.state.profile.v_password) {
      this.props.lang.lang === 'en'
        ? toast.error('Password and confirmation must be matched')
        : this.props.lang.lang === 'gr'
        ? toast.error('Passwort und Bestätigung müssen übereinstimmen')
        : toast.error('يجب أن تتطابق كلمة المرور مع التأكيد')
      return
    }
    let payload = {
      name: this.state.profile.name,
      email: this.state.profile.email,
      fcm_token: 'fcm_token',
      password: this.state.profile.password,
      google_id: Auth.getGoogleUserDetails().sub,
      image: ''
    }
    LoginService.handleGoogleUser(payload)
      .then(resp => {
        console.log(resp)
        if (resp.status === 'success') {
          const new_user = {
            token: resp.token,
            user: resp.user
          }
          Auth.setUserToken(new_user)
          window.location.replace('/')
        }
      })
      .catch(error => {
        console.log('Api Error:', error)
        return error
      })
  }
  render () {
    return (
      <div className='shopping--cart' data-aos='fade-up'>
        <div className='container'>
          <div className='shopping--cart--container'>
            <div className='row my-3'>
              <Heading
                title={
                  this.props.lang.lang === 'en'
                    ? 'My Profile'
                    : this.props.lang.lang === 'gr'
                    ? 'Mein Profil'
                    : 'الحساب الشخصي'
                }
                data-aos='fade-up'
              />
            </div>
            <div style={{ height: 'auto' }}>
              <div className='cold-md-6'>
                <form>
                  <div class='form-group'>
                    <label
                      for='exampleInputEmail1'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? { width: '100%', textAlign: 'left' }
                          : { width: '100%', textAlign: 'right' }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? 'Email'
                        : this.props.lang.lang === 'gr'
                        ? 'Email'
                        : 'الايميل الالكتروني'}
                    </label>
                    <input
                      type='email'
                      class='form-control'
                      id='exampleInputEmail1'
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            email: event.target.value
                          }
                        })
                      }}
                      value={this.state.profile.email}
                      aria-describedby='emailHelp'
                    />
                  </div>
                  <div class='form-group'>
                    <label
                      for='exampleInputName'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? { width: '100%', textAlign: 'left' }
                          : { width: '100%', textAlign: 'right' }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? 'Name'
                        : this.props.lang.lang === 'gr'
                        ? 'Name'
                        : 'الاسم'}
                    </label>
                    <input
                      type='text'
                      class='form-control'
                      id='exampleInputName'
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            name: event.target.value
                          }
                        })
                      }}
                      value={this.state.profile.name}
                      aria-describedby='emailHelp'
                    />
                  </div>
                  <div className='form-group log-status'>
                    <label
                      for='exampleInputName'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? {
                              width: '100%',
                              textAlign: 'left',
                              marginTop: '.5em'
                            }
                          : {
                              width: '100%',
                              textAlign: 'right',
                              paddingRight: '2em',
                              marginTop: '.5em'
                            }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? 'Password'
                        : this.props.lang.lang === 'gr'
                        ? 'Passwort'
                        : 'كلمة المرور'}
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      required
                      placeholder={
                        this.props.lang.lang === 'en'
                          ? 'Password'
                          : this.props.lang.lang === 'gr'
                          ? 'Passwort'
                          : 'كلمة المرور'
                      }
                      id='Passwod'
                      name='password'
                      value={this.state.profile.password}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            password: event.target.value
                          }
                        })
                      }}
                      autoComplete='false'
                    />
                    <i className='fa fa-lock'></i>
                  </div>
                  <div className='form-group log-status'>
                    <label
                      for='exampleInputName'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? {
                              width: '100%',
                              textAlign: 'left',
                              marginTop: '.5em'
                            }
                          : {
                              width: '100%',
                              textAlign: 'right',
                              paddingRight: '2em',
                              marginTop: '.5em'
                            }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? 'Confirm Password'
                        : this.props.lang.lang === 'gr'
                        ? 'Passwort bestätigen'
                        : 'تأكيد كلمة المرور'}
                    </label>
                    <input
                      type='password'
                      className='form-control'
                      required
                      placeholder={
                        this.props.lang.lang === 'en'
                          ? 'Confirm Password'
                          : this.props.lang.lang === 'gr'
                          ? 'Passwort bestätigen'
                          : 'تأكيد كلمة المرور'
                      }
                      id='v_password'
                      name='v_password'
                      value={this.state.profile.v_password}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            v_password: event.target.value
                          }
                        })
                      }}
                      autoComplete='false'
                    />
                    <i className='fa fa-lock'></i>
                  </div>
                  <button
                    type='button'
                    onClick={this.saveMyGoogleProfile}
                    class='btn btn-primary'
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProfileGoogle
