import React, { Component } from 'react'
import Heading from '../../components/Heading'
import { Button } from 'react-bootstrap'
import CalculateTax from '../../utils/CalculateTax'
import EmptyCart from '../../assets/images/empty_cart.png'
import CartService from '../../axios/cartService'
import { toast } from 'react-toastify'
import UserService from '../../axios/userService'
import { BASE_URL } from '../../base'

class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      profile: {},
      myOrders: [],
      subjects: [],
      compImgStyle: {
        display: 'none'
      },
      selectedFile: '',
      message: '',
      subject: '',
      file: null
    }
    this.saveMyProfile = this.saveMyProfile.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.saveComplaint = this.saveComplaint.bind(this)
  }

  componentDidMount = () => {
    this.getMyProfile()
    this.getSubejcts()
  }

  saveComplaint = () => {
    if (!this.state.message) {
      toast.error('Complaint is required')
      return
    }
    var data = new FormData()
    let user = JSON.parse(localStorage.getItem('auth'))?.user
    if (!!this.state.message) data.append('message', this.state['message'])
    if (!!this.state.subject) data.append('subject', this.state['subject'])
    if (!!this.state.selectedFile) data.append('image', this.state.selectedFile)
    data.append('name', user['name'])
    data.append('email', user['email'])
    UserService.sendComplaint(data)
      .then(resp => {
        if (!!resp) {
          toast.success('Complaint is sent')
          this.setState({
            compImgStyle: {
              display: 'none'
            },
            selectedFile: '',
            message: '',
            subject: '',
            file: null
          })
        }
      })
      .catch(er => console.error(er))
  }

  handleChange = e => {
    this.setState({
      selectedFile: e.target.files[0],
      file: URL.createObjectURL(e.target.files[0]),
      compImgStyle: {
        marginTop: '1em',
        display: 'inline-block'
      }
    })
  }

  getSubejcts = () => {
    this.setState({
      subjects: ['1', '2', '3', '4', '5', '6'],
      subject: '1'
    })
  }

  getMyProfile = () => {
    UserService.myProfile()
      .then(resp => {
        this.setState({ profile: resp[0] })
        UserService.myOrder().then(res => {
          console.log(res)
          this.setState({ myOrders: res })
        })
      })
      .catch(er => console.error(er))
  }

  cashOrder = id => {
    UserService.payStribe(id)
      .then(resp => {
        console.log(resp)
        if (resp.success) {
          window.open(resp.data['0'], '_blank')
        }
      })
      .catch(er => console.error(er))
  }

  saveMyProfile = () => {
    let payload = {
      name: this.state.profile.name,
      email: this.state.profile.email,
      phone: this.state.profile.phone,
      city: this.state.profile.city,
      role: this.state.profile.role,
      address: this.state.profile.address,
      birthday: this.state.profile.birthday
    }
    console.log(payload)
    var data = new FormData()
    Object.keys(payload).forEach(e => {
      data.append(e, payload[e])
    })
    UserService.editUser(data).then(resp => {
      console.log(resp)
    })
  }
  render () {
    return (
      <div className='shopping--cart' data-aos='fade-up'>
        <div className='container'>
          <div className='shopping--cart--container'>
            <div className='row my-3'>
              <Heading
                title={
                  this.props.lang.lang === 'en'
                    ? 'My Profile'
                    : this.props.lang.lang === 'gr'
                    ? 'Mei Profil'
                    : 'الحساب الشخصي'
                }
                data-aos='fade-up'
              />
            </div>
            <div style={{ height: 'auto' }}>
              <div className='cold-md-6'>
                <form>
                  <div class='form-group'>
                    <label
                      for='exampleInputEmail1'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? { width: '100%', textAlign: 'left' }
                          : { width: '100%', textAlign: 'right' }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? 'Email'
                        : this.props.lang.lang === 'gr'
                        ? 'Email'
                        : 'البريد الالكتروني'}
                    </label>
                    <input
                      type='email'
                      class='form-control'
                      id='exampleInputEmail1'
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            email: event.target.value
                          }
                        })
                      }}
                      value={this.state.profile.email}
                      aria-describedby='emailHelp'
                      placeholder='Enter email'
                    />
                  </div>
                  <div class='form-group'>
                    <label
                      for='exampleInputName'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? { width: '100%', textAlign: 'left' }
                          : { width: '100%', textAlign: 'right' }
                      }
                    >
                      {this.props.lang === 'en'
                        ? 'Name'
                        : this.props.lang === 'gr'
                        ? 'Name'
                        : 'الاسم'}
                    </label>
                    <input
                      type='text'
                      class='form-control'
                      id='exampleInputName'
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            name: event.target.value
                          }
                        })
                      }}
                      value={this.state.profile.name}
                      aria-describedby='emailHelp'
                      placeholder='Enter email'
                    />
                  </div>
                  <div class='form-group'>
                    <label
                      for='exampleInputPassword1'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? { width: '100%', textAlign: 'left' }
                          : { width: '100%', textAlign: 'right' }
                      }
                    >
                      {this.props.lang === 'en'
                        ? 'Phone'
                        : this.props.lang === 'gr'
                        ? 'Telefon'
                        : 'رقم الهاتف'}
                    </label>
                    <input
                      type='text'
                      value={this.state.profile.phone}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            phone: event.target.value
                          }
                        })
                      }}
                      class='form-control'
                      id='exampleInputPassword1'
                      placeholder='Password'
                    />
                  </div>
                  <div class='form-group'>
                  <label
                      for='exampleInputPassword1'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? { width: '100%', textAlign: 'left' }
                          : { width: '100%', textAlign: 'right' }
                      }
                    >
                      {this.props.lang === 'en'
                        ? 'City'
                        : this.props.lang === 'gr'
                        ? 'Stadt'
                        : 'المدينة'}
                    </label>
                    <input
                      type='text'
                      value={this.state.profile.city}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            city: event.target.value
                          }
                        })
                      }}
                      class='form-control'
                      id='exampleInputPassword1'
                      placeholder='City'
                    />
                  </div>
                  <div class='form-group'>
                    <label
                      for='exampleInputPassword1'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? { width: '100%', textAlign: 'left' }
                          : { width: '100%', textAlign: 'right' }
                      }
                    >
                      {this.props.lang === 'en'
                        ? 'Address'
                        : this.props.lang === 'gr'
                        ? 'Adresse'
                        : 'العنوان'}
                    </label>
                    <input
                      type='text'
                      value={this.state.profile.address}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            address: event.target.value
                          }
                        })
                      }}
                      class='form-control'
                      id='exampleInputPassword1'
                      placeholder='Address'
                    />
                  </div>
                  <div class='form-group'>
                    <label
                      for='exampleInputPassword1'
                      style={
                        this.props.lang.lang === 'en' ||
                        this.props.lang.lang === 'gr'
                          ? { width: '100%', textAlign: 'left' }
                          : { width: '100%', textAlign: 'right' }
                      }
                    >
                      {this.props.lang === 'en'
                        ? 'Birthday'
                        : this.props.lang === 'gr'
                        ? 'Geburtstag'
                        : 'تاريخ الميلاد'}
                    </label>
                    <input
                      type='date'
                      value={this.state.profile.birthday}
                      onChange={event => {
                        this.setState({
                          profile: {
                            ...this.state.profile,
                            birthday: event.target.value
                          }
                        })
                      }}
                      class='form-control'
                      id='exampleInputPassword1'
                      placeholder='Birthday'
                    />
                  </div>
                  <button
                    type='button'
                    onClick={this.saveMyProfile}
                    class='btn btn-primary'
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='shopping--cart--container'>
            <div className='row my-5'>
              <Heading
                title={this.props.lang.lang == 'en' ? 'My Orders' : 'طلباتي'}
                data-aos='fade-up'
              />
            </div>
            <div style={{ height: 'auto' }}>
              {!!this.state.myOrders &&
                this.state.myOrders.length > 0 &&
                this.state.myOrders.map(order => {
                  return (
                    <div
                      className='row my-3'
                      style={{
                        border: '1px solid orange',
                        borderRadius: '20px',
                        padding: '1em'
                      }}
                    >
                      <div className='col-md-6'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en' ? 'Date' : 'التاريخ'}
                        </strong>
                        {order.date}
                      </div>
                      <div className='col-md-3'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en'
                            ? 'Status'
                            : 'حالة الطلب'}
                        </strong>
                        <span
                          style={{
                            backgroundColor: order.order_status?.color
                          }}
                        >
                          {order.order_status?.name}
                        </span>
                      </div>
                      <div className='col-md-3'>
                        <button
                          type='button'
                          disabled={order.is_paid === 1}
                          onClick={() => this.cashOrder(order.id)}
                          class='btn btn-primary'
                        >
                          {this.props.lang.lang == 'en' ? 'Pay' : 'دفع'}
                        </button>
                      </div>
                      <div className='col-md-6'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en' ? 'Address' : 'العنوان'}
                        </strong>
                        {order.address}
                      </div>
                      <div className='col-md-6'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en'
                            ? 'Sub Total'
                            : 'المجزأ'}
                        </strong>
                        {order.sub_total}
                      </div>
                      <div className='col-md-3'>
                        <strong style={{ margin: '0 1em' }}>
                          {this.props.lang.lang == 'en' ? 'Total' : 'الإجمالي'}
                        </strong>
                        {order.total}
                      </div>
                      <div className='col-md-12'>
                        <table className='table table-borderd table-striped'>
                          <thead>
                            <tr>
                              <th>
                                {this.props.lang.lang == 'en'
                                  ? 'Product Name'
                                  : 'اسم المنتج'}
                              </th>
                              <th>
                                {this.props.lang.lang == 'en'
                                  ? 'Product Description'
                                  : 'وصف المنتج'}
                              </th>
                              <th>
                                {this.props.lang.lang == 'en'
                                  ? 'Price'
                                  : 'السعر'}
                              </th>
                              <th>
                                {this.props.lang.lang == 'en'
                                  ? 'Image'
                                  : 'الصورة'}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {order?.items.map(item => {
                              return (
                                <tr>
                                  <td>
                                    {this.props.lang.lang == 'en'
                                      ? item.product.en_name
                                      : item.product.ar_name}
                                  </td>
                                  <td>
                                    {this.props.lang.lang == 'en'
                                      ? item.product.en_description
                                      : item.product.ar_description}
                                  </td>
                                  <td>
                                    {this.props.lang.lang == 'en'
                                      ? item.product.selling_price
                                      : item.product.selling_price}
                                  </td>
                                  <td>
                                    <img
                                      src={`${BASE_URL}upload/${item.product.image}`}
                                      alt=''
                                      className='img-fluid'
                                    />
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='shopping--cart--container'>
            <div className='row my-5'>
              <Heading
                title={this.props.lang.lang == 'en' ? 'Complaint' : 'الشكوى'}
                data-aos='fade-up'
              />
            </div>
            <div style={{ height: 'auto' }}>
              <form>
                <div class='form-group'>
                  <label for='exampleInputPassword1'>Subject</label>
                  <select
                    onChange={e => this.setState({ subject: e.target.value })}
                    value={this.state.subject}
                    className='form-control select mx-1 my-3'
                  >
                    {this.state.subjects &&
                      this.state.subjects.map(e => (
                        <option key={e} value={e}>
                          {e}
                        </option>
                      ))}
                  </select>
                </div>
                <div class='form-group'>
                  <label for='exampleInputC'>Complaint</label>
                  <input
                    type='text'
                    value={this.state.message}
                    onChange={e => this.setState({ message: e.target.value })}
                    name='message'
                    class='form-control'
                    id='exampleInputC'
                    placeholder='Complaint'
                  />
                </div>
                <div class='form-group'>
                  <label>Image</label>
                  <input
                    type='file'
                    onChange={e =>
                      this.setState({
                        selectedFile: e.target.files[0],
                        file: URL.createObjectURL(e.target.files[0]),
                        compImgStyle: {
                          marginTop: '1em',
                          display: 'inline-block'
                        }
                      })
                    }
                    class='form-control'
                    placeholder='image'
                  />
                  <img
                    style={this.state.compImgStyle}
                    width='200'
                    height='200'
                    alt='complaint'
                    src={this.state.file}
                  />
                </div>
                <button
                  type='button'
                  onClick={this.saveComplaint}
                  class='btn btn-primary'
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Profile
