import React from 'react'

function Footer (props) {
  return (
    <footer className='footer'>
      <div className='container'>
        <div
          className='row'
          style={
            props.lang === 'en' ? { direction: 'ltr' } : { direction: 'rtl' }
          }
        >
          <div className='col-lg-6'>
            <div className='footer_nav_container d-flex flex-sm-row flex-column align-items-center justify-content-lg-start justify-content-center text-center'>
              <ul className='footer_nav'>
                <li>
                  <a href='#'>
                    {props.lang === 'en' || props.lang === 'gr'
                      ? 'Blogs'
                      : 'مقالات'}
                  </a>
                </li>
                <li>
                  <a href='#'>
                    {props.lang === 'en' || props.lang === 'gr'
                      ? 'FAQs'
                      : 'الأسئلة الشائعة'}
                  </a>
                </li>
                <li className={props.lang === 'ar' ? 'marg' : ''}>
                  <a href='contact.html'>
                    {props.lang === 'en'
                      ? 'Contact us'
                      : props.lang === 'gr'
                      ? 'Kontaktieren Sie uns'
                      : 'تواصل معنا'}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='footer_social d-flex flex-row align-items-center justify-content-lg-end justify-content-center'>
              <ul>
                <li>
                  <a href='#'>
                    <i className='fab fa-facebook-f'></i>
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='fab fa-twitter'></i>{' '}
                  </a>
                </li>
                <li>
                  <a href='#'>
                    <i className='fab fa-instagram'></i>{' '}
                  </a>
                </li>

                <li>
                  <a href='#'>
                    <i className='fab fa-pinterest-p'></i>{' '}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
