import api from "./API";
import instance from "./API";
import axios from "axios";
import https from "https";
import Auth from "../modules/Auth";
import { BASE_URL } from "./../base";
export default class LoginService {
  static async LoginType(type) {
    window.open(BASE_URL + "api/auth/" + type);
  }
  static verifyCode(_email, code) {
    const payload = {
      verification_code: code,
      email: _email,
    };
    return instance
      .post("/verifyCode", payload)
      .then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          return true;
        } else return false;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
  static async handleGoogleUser(payload) {
    return instance
      .post("/handleGoogleUser", payload)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 'success') {
          const new_user = {
            token: resp.token,
            user: resp.user,
          };
          Auth.setUserToken(new_user);
          return resp;
        }
      })
      .catch((error) => {
        console.log("Api Error:", error);
        return error;
      });
  }
  static async Login(_email, _password) {
    const payload = {
      email: _email,
      password: _password,
      fcm_token: "afasdasd",
    };
    return instance
      .post("/login", payload)
      .then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          const new_user = {
            token: resp.data.data.token,
            user: resp.data.data.user,
          };
          Auth.setUserToken(new_user);
          return resp.data;
        }
      })
      .catch((error) => {
        console.log("Api Error:", error);
        return error;
      });
  }
  static async Register(name, email, password, v_pasword, address) {
    const payload = {
      name: name,
      email: email,
      password: password,
      c_password: v_pasword,
      address: address,
      fcm_token: "frfr",
    };
    return instance
      .post("/register", payload)
      .then((resp) => {
        console.log("serv", resp.data);
        if (resp.data.success) {
          return resp.data;
        }
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
  static async LoginWithGoogle(_email, _password) {
    return api
      .get("/auth/google")
      .then((resp) => {
        if (resp.data.success) return resp.data.data;
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
  static async LoginWithFacebook(_email, _password) {
    const payload = {
      email: _email,
      password: _password,
      fcm_token: "afasdasd",
    };
    return api
      .post("/auth/facebook", payload)
      .then((resp) => {
        if (resp.data.success) return resp.data.data;
      })
      .catch((error) => {
        console.log("Api Error:", error);
        throw error;
      });
  }
}
