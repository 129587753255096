

import {
  getNewArrivalProducts,
  applyFilters
} from "../../redux/actions/productAction";
import { connect } from "react-redux";
import Home from "./Home";
import { postCart } from "../../redux/actions/cartAction";

const mapStoreToProps = state => ({
  newArrivalProducts: state.product.newArrivalProducts,
  loading: state.product.loading,
  lang: state.lang,
  currency: state.currency,
  ss: state
});
const mapDispatchToProps = dispatch => ({
  getNewArrivalProducts: () => dispatch(getNewArrivalProducts()),
  applyFilters: filter_string => dispatch(applyFilters(filter_string)),
  postCart: (productId,count,color, price, image, name) => dispatch(postCart(productId, count, color, price, image, name)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Home);
