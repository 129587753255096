import React, { useState } from 'react'
import jumpTo from '../../modules/Navigation'
import { BASE_URL } from '../../base'
import { toast } from 'react-toastify'

function SingleProduct (props) {
  const { productItem } = props
  const [color, setColor] = useState('')
  const [qty, setQty] = useState(0)
  console.log(productItem)
  const addToCart = (id, price, image, name) => {
    props.addToBag(id, qty, color, price, image, name)
  }
  const onAddClicked = () => {
    if (qty + 1 <= productItem.entity) setQty(qty + 1)
  }
  const onRemoveClicked = () => {
    if (qty - 1 <= productItem.entity) setQty(qty - 1)
  }
  const [img, setImg] = useState(productItem.image)
  return (
    <div className='product-item men'>
      <div
        className='product discount product_filter'
        onClick={() => jumpTo(`/single-product/${productItem.id}`)}
      >
        <div className='product_image'>
          <img src={`${BASE_URL}/upload/${img}`} alt='' className='img-fluid' />
        </div>
        <div className='favorite favorite_left'>
          <i className='far fa-heart' />
        </div>
        <div className='product_info'>
          <h6 className='product_name'>
            <div>
              {props.lang === 'en'
                ? productItem.en_name
                : props.lang === 'gr'
                ? productItem.de_name
                : productItem.ar_name}
            </div>
          </h6>
          <div className='product_color' style={{ height: '2em' }}>
            <span style={{ fontWeight: 'bold' }}>
              {props.lang === 'en'
                ? `colors:`
                : props.lang === 'gr'
                ? 'Farben:'
                : ''}
            </span>
            {
              <>
                <ul style={{ marginLeft: '0px' }}>
                  {productItem.color &&
                    JSON.parse(productItem.color).map(e => (
                      <li
                        style={{
                          background: `${e}`,
                          border: '1px solid black',
                          marginRight: '5px',
                          zIndex: '999'
                        }}
                      ></li>
                    ))}
                </ul>
              </>
            }
          </div>

          <div className='product_price mt-n3'>
            {props.currency == 'dollar'
              ? `${productItem.selling_price} $`
              : `${productItem.selling_price * 250.27} YRI`}
          </div>
        </div>
      </div>
      <div className='add_to_cart_button' style={{ height: '5em' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '.5em'
          }}
        >
          <span
            style={{ color: 'black', marginRight: '5px', fontWeight: 'bold' }}
          >
            {props.lang === 'en'
              ? ` select color`
              : props.lang === 'gr'
              ? 'Farbe wählen'
              : ''}
          </span>
          <ul style={{ display: 'flex', justifyContent: 'center' }}>
            {productItem.color &&
              JSON.parse(productItem.color)
                .filter((item, index) => index < 6)
                .map(e => (
                  <li
                    style={{
                      background: `${e}`,
                      border: '1px solid black',
                      marginRight: '5px',
                      borderRadius: '50%',
                      height: '1.8em'
                    }}
                  >
                    <input
                      type='radio'
                      name={`color-${productItem.id}`}
                      value={e}
                      style={{
                        background: `${e}`,
                        border: '1px solid black',
                        margin: '3px 3px 0 3px'
                      }}
                      onChange={event => {
                        let colors = JSON.parse(props.productItem.color)
                        let index =
                          colors && colors.length > 0
                            ? colors.findIndex(c => c === event.target.value)
                            : 0
                        index > 0
                          ? setImg(props.productItem['image_' + ++index])
                          : setImg(props.productItem['image'])
                        setColor(event.target.value)
                      }}
                    />
                  </li>
                ))}
          </ul>
          <br />
          <ul style={{ display: 'flex', justifyContent: 'center' }}>
            {productItem.color &&
              JSON.parse(productItem.color)
                .filter((item, index) => index >= 6)
                .map(e => (
                  <li
                    style={{
                      background: `${e}`,
                      border: '1px solid black',
                      marginRight: '5px',
                      borderRadius: '50%',
                      height: '1.8em'
                    }}
                  >
                    <input
                      type='radio'
                      name={`color-${productItem.id}`}
                      value={e}
                      style={{
                        background: `${e}`,
                        border: '1px solid black',
                        margin: '3px 3px 0 3px'
                      }}
                      onChange={event => {
                        let colors = JSON.parse(props.productItem.color)
                        let index =
                          colors && colors.length > 0
                            ? colors.findIndex(c => c === event.target.value)
                            : 0
                        index > 0
                          ? setImg(props.productItem['image_' + ++index])
                          : setImg(props.productItem['image'])
                        setColor(event.target.value)
                      }}
                    />
                  </li>
                ))}
          </ul>
        </div>
      </div>
      <div
        className='quantity d-flex flex-column flex-sm-row align-items-sm-center'
        style={
          props.lang === 'en' || props.lang === 'gr'
            ? { marginTop: '-.5px', direction: 'ltr' }
            : { marginTop: '-.5px', direction: 'rtl' }
        }
      >
        <span
          style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }}
        >
          {props.lang === 'en'
            ? `All(${productItem.entity})`
            : props.lang === 'gr'
            ? `Alle (${productItem.entity})`
            : `العدد الكلي (${productItem.entity})`}
        </span>
        <div className='quantity_selector'>
          <span
            className={qty > 1 ? 'minus' : 'minus disabled'}
            onClick={() => onRemoveClicked()}
          >
            <i className='fa fa-minus' aria-hidden='true'></i>
          </span>
          <span id='quantity_value'>{qty}</span>
          <span className='plus' onClick={() => onAddClicked()}>
            <i className='fa fa-plus' aria-hidden='true'></i>
          </span>
        </div>
      </div>
      <div
        className='red_button add_to_cart_button'
        onClick={() =>
          addToCart(
            productItem.id,
            productItem.selling_price,
            `${BASE_URL}upload/${img}`,
            productItem.en_name
          )
        }
      >
        <div style={{ color: '#ffffff' }}>
          {props.lang === 'en'
            ? `add to cart`
            : props.lang === 'gr'
            ? 'in den Warenkorb legen'
            : `إضافة إلى السلة`}
        </div>
      </div>
    </div>
  )
}

export default SingleProduct
