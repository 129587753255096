import React, { Component } from 'react'
import SingleProduct from '../../components/Products/SingleProduct'
import Auth from '../../modules/Auth'
import LoginRegister from '../../components/LoginRegisterModal'
import Filter from './components/Filter'
import { toast } from 'react-toastify'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom'

class Policy extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  componentDidMount () {}
  showHideModal = () => {
    this.setState({ modalShow: false })
  }

  loginClicked = () => {
    this.setState({ modalShow: true, login: true })
  }
  registerClicked = () => {
    this.setState({ modalShow: true, login: false })
  }

  componentDidUpdate (prevProps, prevState) {}

  render () {
    return (
      <div
        style={
          this.props.lang.lang === 'ar'
            ? { direction: 'rtl' }
            : { direction: 'ltr' }
        }
        className='container product_section_container'
      >
        <div className='row'>
          <h1>
            {this.props.lang.lang === 'en'
              ? 'Privacy Policy'
              : this.props.lang.lang === 'gr'
              ? 'Datenschutzerklärung'
              : 'سياسة الخصوصية'}
          </h1>
          <br />
          <hr />
          <div
            className='row'
            style={
              this.props.lang.lang === 'ar'
                ? { direction: 'rtl' }
                : { direction: 'ltr' }
            }
          >
            {this.props.lang.lang === 'en' ? (
              <>
                <p>
                  In this privacy policy we have compiled all essential
                  information about how we handle your personal data and your
                  rights in this regard.
                </p>
                <ul>
                  <li>
                    <p>
                      Scope and updating of this privacy policy This privacy
                      policy applies to the use of this website and all
                      applications, services (including payment services),
                      products and tools (collectively "Services") of
                      Celloshall. This privacy policy applies regardless of how
                      you access or use our services, including access via
                      mobile devices or via app. We reserve the right to update
                      this privacy policy as necessary to adapt it to changed
                      legal, technical or business requirements.
                    </p>
                  </li>
                  <li>
                    <p>
                      Responsible for data processing: Celloshall
                      Friedrichstraße 159 42551 Velbert Germany E-Mail:
                      shallcello04@gmail.com. Celloshall is solely responsible
                      for the collection and processing of your personal data in
                      connection with the provision of our services. Our service
                      area covers Germany exclusively, and deliveries are
                      currently only made within Germany.
                    </p>
                  </li>
                  <li>
                    <p>
                      What personal data we collect and process We collect
                      personal data when you use our services, create a new
                      Celloshall account, provide us with information through a
                      web form, add or update information to your account,
                      participate in community discussions, or otherwise contact
                      us. The data we collect includes:- Name, address, phone
                      number, and email address- Payment information (e.g.
                      credit card details, PayPal address)- Login details (e.g.
                      username and password)- Information about purchases,
                      deliveries, and returns- Device and usage data (e.g. IP
                      address, browser type, operating system)
                    </p>
                  </li>
                  <li>
                    <p>
                      Purposes and legal bases of data processing We process
                      your personal data for the following purposes:
                      <strong>a-</strong> To provide and improve our services-
                      Processing of orders, payments and deliveries-
                      Personalizing your user experience- Managing your
                      Celloshall account Legal basis: Art. 6 Paragraph 1 lit. b
                      GDPR (fulfillment of a contract). <br />
                      <strong>b-</strong> Customer service- Processing of
                      inquiries and complaints- Provision of support Legal
                      basis: Art. 6 Para. 1 lit. b GDPR (fulfillment of a
                      contract).
                      <br />
                      <strong>c-</strong> Marketing and advertising- Sending
                      personalized offers and information about our products-
                      Analysis of user data to improve our marketing measures
                      Legal basis: Art. 6 Para. 1 lit. a GDPR (consent) or Art.
                      6 Para. 1 lit. f GDPR (legitimate interest).
                      <br />
                      <strong>d-</strong> Security and fraud prevention -
                      detection, prevention and investigation of fraudulent or
                      illegal activities Legal basis: Art. 6 para. 1 lit. c GDPR
                      (legal obligation) or Art. 6 para. 1 lit. f GDPR
                      (legitimate interest).
                    </p>
                  </li>
                  <li>
                    <p>
                      Transfer of data We only pass on your personal data to
                      third parties if this is necessary:
                      <br />
                      - Shipping service providers (e.g. DHL, Hermes) for the
                      delivery of your orders - Payment service providers (e.g.
                      PayPal) for the processing of payments
                      <br />- IT service providers for the maintenance and
                      provision of our website Legal basis: Art. 6 para. 1 lit.
                      b GDPR (fulfillment of a contract).
                    </p>
                  </li>
                  <li>
                    <p>
                      Your rights You have the right: - to request information
                      about your stored personal data (Art. 15 GDPR) <br />
                      - to request correction of incorrect or incomplete data
                      (Art. 16 GDPR) <br />
                      - to request deletion of your data, provided that there
                      are no statutory retention obligations to the contrary
                      (Art. 17 GDPR) <br />- to request restriction of the
                      processing of your data (Art. 18 GDPR), <br />- to receive
                      your data in a transferable format (Art. 20 GDPR) - to
                      revoke consent given at any time (Art. 7 Para. 3 GDPR),
                      <br />- to complain to a supervisory authority (Art. 77
                      GDPR).
                    </p>
                  </li>
                  <li>
                    <p>
                      Cookies and tracking technologies Our website uses cookies
                      to improve user-friendliness and to optimize our offering.
                      You can control the use of cookies in your browser
                      settings.
                    </p>
                  </li>
                  <li>
                    <p>
                      Current status and changes to this privacy policy This
                      privacy policy is currently valid and is dated
                      [month/year]. We reserve the right to update it if
                      necessary.
                    </p>
                  </li>
                </ul>
              </>
            ) : this.props.lang.lang === 'gr' ? (
              <>
                <p>
                  In dieser Datenschutzerklärung haben wir alle wesentlichen
                  Informationen über unseren Umgang mit Ihren personenbezogenen
                  Daten und Ihre diesbezüglichen Rechte zusammengestellt.
                </p>
                <ul>
                  <li>
                    <p>
                      Anwendungsbereich und Aktualisierung dieser
                      Datenschutzerklärung Diese Datenschutzerklärung gilt für
                      die Nutzung dieser Website und aller Anwendungen,
                      Dienstleistungen (einschließlich Zahlungsdiensten),
                      Produkte und Tools (zusammenfassend "Services") von
                      Celloshall. Diese Datenschutzerklärung gilt unabhängig
                      davon, wie Sie unsere Services aufrufen oder verwenden,
                      einschließlich des Zugriffs über Mobilgeräte oder per App.
                      Wir behalten uns vor, diese Datenschutzerklärung bei
                      Bedarf zu aktualisieren, um sie an geänderte rechtliche,
                      technische oder geschäftliche Anforderungen anzupassen.
                    </p>
                  </li>
                  <li>
                    <p>
                      Verantwortlicher Verantwortlich für die Datenverarbeitung:
                      Celloshall Friedrichstraße 159 42551 Velbert Deutschland
                      E-Mail: shallcello04@gmail.com Celloshall ist allein für
                      die Erfassung und Verarbeitung Ihrer personenbezogenen
                      Daten im Zusammenhang mit der Bereitstellung unserer
                      Services verantwortlich. Unser Servicegebiet umfasst
                      ausschließlich Deutschland, und Lieferungen erfolgen
                      derzeit nur innerhalb Deutschlands.
                    </p>
                  </li>
                  <li>
                    <p>
                      Welche personenbezogenen Daten wir erheben und verarbeiten
                      Wir erheben personenbezogene Daten, wenn Sie unsere
                      Services nutzen, ein neues Celloshall-Konto erstellen, uns
                      Informationen über ein Webformular mitteilen,
                      Informationen zu Ihrem Konto hinzufügen oder
                      aktualisieren, sich an Community-Diskussionen beteiligen
                      oder auf andere Weise mit uns in Kontakt treten. Zu den
                      von uns erhobenen Daten gehören:- Name, Adresse,
                      Telefonnummer und E-Mail-Adresse- Zahlungsinformationen
                      (z. B. Kreditkartendaten, PayPal-Adresse)- Anmeldedaten
                      (z. B. Benutzername und Passwort)- Informationen zu
                      Käufen, Lieferungen und Rücksendungen- Geräte- und
                      Nutzungsdaten (z. B. IP-Adresse, Browsertyp,
                      Betriebssystem)
                    </p>
                  </li>
                  <li>
                    <p>
                      Zwecke und Rechtsgrundlagen der Datenverarbeitung Wir
                      verarbeiten Ihre personenbezogenen Daten zu folgenden
                      Zwecken:
                      <strong>a-</strong> Zur Bereitstellung und Verbesserung
                      unserer Services- Abwicklung von Bestellungen, Zahlungen
                      und Lieferungen- Personalisierung Ihres Nutzererlebnisses-
                      Verwaltung Ihres Celloshall-Kontos Rechtsgrundlage: Art. 6
                      Abs. 1 lit. b DSGVO (Erfüllung eines Vertrags). <br />
                      <strong>b-</strong> Kundenservice- Bearbeitung von
                      Anfragen und Beschwerden- Bereitstellung von Support
                      Rechtsgrundlage: Art. 6 Abs. 1 lit. b DSGVO (Erfüllung
                      eines Vertrags). <br />
                      <strong>c-</strong> Marketing und Werbung- Zusendung
                      personalisierter Angebote und Informationen über unsere
                      Produkte- Analyse von Nutzerdaten zur Verbesserung unserer
                      Marketingmaßnahmen Rechtsgrundlage: Art. 6 Abs. 1 lit. a
                      DSGVO (Einwilligung) oder Art. 6 Abs. 1 lit. f DSGVO
                      (berechtigtes Interesse).
                      <br />
                      <strong>d-</strong> Sicherheit und Betrugsprävention-
                      Ermittlung, Verhinderung und Untersuchung von
                      betrügerischen oder rechtswidrigen Aktivitäten
                      Rechtsgrundlage: Art. 6 Abs. 1 lit. c DSGVO (gesetzliche
                      Verpflichtung) oder Art. 6 Abs. 1 lit. f DSGVO
                      (berechtigtes Interesse).
                    </p>
                  </li>
                  <li>
                    <p>
                      Weitergabe von Daten Wir geben Ihre personenbezogenen
                      Daten nur dann an Dritte weiter, wenn dies erforderlich
                      ist: <br />
                      - Versanddienstleister (z. B. DHL, Hermes) für die
                      Zustellung Ihrer Bestellungen <br />-
                      Zahlungsdienstleister (z. B. PayPal) zur Abwicklung von
                      Zahlungen- IT-Dienstleister zur Wartung und Bereitstellung
                      unserer Website Rechtsgrundlage: Art. 6 Abs. 1 lit. b
                      DSGVO (Erfüllung eines Vertrags).
                    </p>
                  </li>
                  <li>
                    <p>
                      Ihre Rechte Sie haben das Recht: - Auskunft über Ihre
                      gespeicherten personenbezogenen Daten zu verlangen (Art.
                      15 DSGVO) <br />
                      - Berichtigung unrichtiger oder unvollständiger Daten zu
                      verlangen (Art. 16 DSGVO) <br />
                      - Löschung Ihrer Daten zu verlangen, soweit keine
                      gesetzlichen Aufbewahrungspflichten entgegenstehen (Art.
                      17 DSGVO) <br />- die Einschränkung der Verarbeitung Ihrer
                      Daten zu verlangen (Art. 18 DSGVO), <br />- Ihre Daten in
                      einem übertragbaren Format zu erhalten (Art. 20 DSGVO) -
                      eine erteilte Einwilligung jederzeit zu widerrufen (Art. 7
                      Abs. 3 DSGVO),
                      <br />- sich bei einer Aufsichtsbehörde zu beschweren
                      (Art. 77 DSGVO).
                    </p>
                  </li>
                  <li>
                    <p>
                      Cookies und Tracking-Technologien Unsere Website verwendet
                      Cookies, um die Benutzerfreundlichkeit zu verbessern und
                      unser Angebot zu optimieren. Sie können die Verwendung von
                      Cookies in den Einstellungen Ihres Browsers steuern.
                    </p>
                  </li>
                  <li>
                    <p>
                      Aktualität und Änderungen dieser Datenschutzerklärung
                      Diese Datenschutzerklärung ist aktuell gültig und hat den
                      Stand [Monat/Jahr]. Wir behalten uns vor, sie bei Bedarf
                      zu aktualisieren.
                    </p>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <p
                  style={
                    this.props.lang.lang === 'ar'
                      ? { direction: 'rtl' }
                      : { direction: 'ltr' }
                  }
                >
                  في سياسة الخصوصية هذه، قمنا بتجميع كل المعلومات الأساسية حول
                  كيفية تعاملنا مع بياناتك الشخصية وحقوقك في هذا الصدد.
                </p>
                <ul>
                  <li>
                    <p
                      style={
                        this.props.lang.lang === 'ar'
                          ? { direction: 'rtl', textAlign: 'right' }
                          : { direction: 'ltr', textAlign: 'left' }
                      }
                    >
                      <strong>نطاق وتحديث سياسة الخصوصية </strong>
                      تنطبق سياسة الخصوصية هذه على استخدام هذا الموقع الإلكتروني
                      وجميع التطبيقات والخدمات (بما في ذلك خدمات الدفع)
                      والمنتجات والأدوات (يشار إليها مجتمعة باسم الخدمات) الخاصة
                      <br />
                      بشركة Celloshall. تنطبق سياسة الخصوصية هذه بغض النظر عن
                      كيفية وصولك إلى خدماتنا أو استخدامها، بما في ذلك الوصول
                      عبر الأجهزة المحمولة أو عبر التطبيق. نحتفظ بالحق في تحديث
                      سياسة الخصوصية هذه حسب الضرورة لتكييفها مع المتطلبات
                      القانونية أو الفنية أو التجارية المتغيرة.
                    </p>
                  </li>
                  <li>
                    <p
                      style={
                        this.props.lang.lang === 'ar'
                          ? { direction: 'rtl', textAlign: 'right' }
                          : { direction: 'ltr', textAlign: 'left' }
                      }
                    >
                      المسؤول عن معالجة البيانات: Celloshall Friedrichstraße 159{' '}
                      42551 Velbert Germany البريد الإلكتروني:
                      shallcello04@gmail.com. Celloshall هي المسؤولة وحدها عن
                      جمع ومعالجة بياناتك الشخصية فيما يتعلق بتقديم خدماتنا.
                      تغطي منطقة خدمتنا ألمانيا حصريًا، ويتم التسليم حاليًا داخل
                      ألمانيا فقط.
                    </p>
                  </li>
                  <li>
                    <p
                      style={
                        this.props.lang.lang === 'ar'
                          ? { direction: 'rtl', textAlign: 'right' }
                          : { direction: 'ltr', textAlign: 'left' }
                      }
                    >
                      ما هي البيانات الشخصية التي نجمعها ونعالجها؟ نجمع البيانات
                      الشخصية عندما تستخدم خدماتنا، أو تنشئ حسابًا جديدًا على
                      Celloshall، أو تزودنا بمعلومات من خلال نموذج ويب، أو تضيف
                      معلومات إلى حسابك أو تحدّثها، أو تشارك في مناقشات المجتمع،
                      أو تتواصل معنا بأي طريقة أخرى. تتضمن البيانات التي
                      نجمعها:- الاسم والعنوان ورقم الهاتف وعنوان البريد
                      الإلكتروني- معلومات الدفع (مثل تفاصيل بطاقة الائتمان،
                      وعنوان PayPal)- تفاصيل تسجيل الدخول (مثل اسم المستخدم
                      وكلمة المرور)- معلومات حول المشتريات، والتسليمات،
                      والإرجاعات- بيانات الجهاز والاستخدام (مثل عنوان IP، ونوع
                      المتصفح، ونظام التشغيل)
                    </p>
                  </li>
                  <li>
                    <p
                      style={
                        this.props.lang.lang === 'ar'
                          ? { direction: 'rtl', textAlign: 'right' }
                          : { direction: 'ltr', textAlign: 'left' }
                      }
                    >
                      أغراض وأسس قانونية لمعالجة البيانات نعالج بياناتك الشخصية
                      للأغراض التالية:
                      <strong>أ-</strong> لتوفير خدماتنا وتحسينها- معالجة
                      الطلبات والمدفوعات والتسليمات- تخصيص تجربة المستخدم- إدارة
                      حسابك في Celloshall الأساس القانوني: المادة 6 الفقرة 1
                      الفقرة ب من اللائحة العامة لحماية البيانات (الوفاء
                      بالعقد). <br />
                      <strong>ب-</strong> خدمة العملاء- معالجة الاستفسارات
                      والشكاوى- توفير الدعم الأساس القانوني: المادة 6 الفقرة 1
                      الفقرة ب من اللائحة العامة لحماية البيانات (الوفاء
                      بالعقد).
                      <br />
                      <strong>ج-</strong> التسويق والإعلان- إرسال عروض ومعلومات
                      مخصصة حول منتجاتنا- تحليل بيانات المستخدم لتحسين تدابير
                      التسويق لدينا الأساس القانوني: المادة 6 الفقرة 1 الفقرة أ
                      من اللائحة العامة لحماية البيانات (الموافقة) أو المادة 6
                      الفقرة 1 الفقرة و من اللائحة العامة لحماية البيانات
                      (المصلحة المشروعة).
                      <br />
                      <strong>د-</strong> الأمن ومنع الاحتيال - الكشف عن الأنشطة
                      الاحتيالية أو غير القانونية ومنعها والتحقيق فيها الأساس
                      القانوني: المادة 6 الفقرة 1 الفقرة ج من اللائحة العامة
                      لحماية البيانات (الالتزام القانوني) أو المادة 6 الفقرة 1
                      الفقرة و من اللائحة العامة لحماية البيانات (المصلحة
                      المشروعة).
                    </p>
                  </li>
                  <li>
                    <p
                      style={
                        this.props.lang.lang === 'ar'
                          ? { direction: 'rtl', textAlign: 'right' }
                          : { direction: 'ltr', textAlign: 'left' }
                      }
                    >
                      نقل البيانات لا ننقل بياناتك الشخصية إلى أطراف أخرى إلا
                      إذا كان ذلك ضروريًا:
                      <br />
                      - مزودو خدمات الشحن (مثل DHL وHermes) لتسليم طلباتك -
                      مزودو خدمات الدفع (مثل PayPal) لمعالجة المدفوعات
                      <br />- مزودو خدمات تكنولوجيا المعلومات لصيانة موقعنا
                      الإلكتروني وتوفيره الأساس القانوني: المادة 6 الفقرة 1
                      الفقرة ب من اللائحة العامة لحماية البيانات (تنفيذ العقد).
                    </p>
                  </li>
                  <li>
                    <p
                      style={
                        this.props.lang.lang === 'ar'
                          ? { direction: 'rtl', textAlign: 'right' }
                          : { direction: 'ltr', textAlign: 'left' }
                      }
                    >
                      حقوقك لديك الحق في: <br /> - طلب معلومات حول بياناتك
                      الشخصية المخزنة (المادة 15 من اللائحة العامة لحماية
                      البيانات) <br />
                      - طلب تصحيح البيانات غير الصحيحة أو غير المكتملة (المادة
                      16 من اللائحة العامة لحماية البيانات) <br />
                      - طلب حذف بياناتك، بشرط عدم وجود التزامات قانونية
                      بالاحتفاظ بها على العكس من ذلك (المادة 17 من اللائحة
                      العامة لحماية البيانات) <br />- طلب تقييد معالجة بياناتك
                      (المادة 18 من اللائحة العامة لحماية البيانات)، تلقي
                      بياناتك بتنسيق قابل للتحويل (المادة 20 من اللائحة العامة
                      لحماية البيانات) - سحب الموافقة الممنوحة في أي وقت (المادة
                      7 الفقرة 3 من اللائحة العامة لحماية البيانات)، تقديم شكوى
                      إلى هيئة إشرافية (المادة 77 من اللائحة العامة لحماية
                      البيانات).
                    </p>
                  </li>
                  <li>
                    <p
                      style={
                        this.props.lang.lang === 'ar'
                          ? { direction: 'rtl', textAlign: 'right' }
                          : { direction: 'ltr', textAlign: 'left' }
                      }
                    >
                      ملفات تعريف الارتباط وتقنيات التتبع يستخدم موقعنا ملفات
                      تعريف الارتباط لتحسين سهولة الاستخدام وتحسين عروضنا. يمكنك
                      التحكم في استخدام ملفات تعريف الارتباط في إعدادات المتصفح
                      الخاص بك.
                    </p>
                  </li>
                  <li>
                    <p
                      style={
                        this.props.lang.lang === 'ar'
                          ? { direction: 'rtl', textAlign: 'right' }
                          : { direction: 'ltr', textAlign: 'left' }
                      }
                    >
                      الحالة الحالية والتغييرات التي تطرأ على سياسة الخصوصية هذه
                      سياسة الخصوصية هذه سارية المفعول حاليًا وتاريخها
                      [شهر/سنة]. نحتفظ بالحق في تحديثها إذا لزم الأمر.
                    </p>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Policy
