import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";

import { Provider } from "react-redux";
import store from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google"

console.log('store', store.getState())
ReactDOM.render(
  <GoogleOAuthProvider clientId='1033817629681-7lq8kbaff8vjdnjf40420nuoja4oug5q.apps.googleusercontent.com'>
  <Provider store={store}>
    <App />
  </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
