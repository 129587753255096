import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Auth from '../../modules/Auth'

class MobileMenu extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    return (
      <div
        className={
          this.props.activeClass ? 'hamburger_menu active' : 'hamburger_menu'
        }
      >
        <div className='hamburger_close' onClick={this.props.onClose}>
          <i className='fa fa-times' aria-hidden='true'></i>
        </div>
        <div className='hamburger_menu_content text-right'>
          <ul className='menu_top_nav'>
            <li className='menu_item'>
              <Link to='/home'>
                {this.props.lang.lang === 'en'
                  ? 'home'
                  : this.props.lang.lang === 'gr'
                  ? 'home'
                  : 'الصفحة الرئيسية'}
              </Link>
            </li>
            <li className='menu_item'>
              <Link to='/products'>
                {this.props.lang.lang === 'en'
                  ? 'products'
                  : this.props.lang.lang === 'gr'
                  ? 'Produkte'
                  : 'المنتجات'}
              </Link>
            </li>
            <li className='menu_item'>
              <Link to='/categories'>
                {this.props.lang.lang === 'en'
                  ? 'categories'
                  : this.props.lang.lang === 'gr'
                  ? 'Kategorien'
                  : 'الأصناف'}
              </Link>
            </li>
            <li className='menu_item'>
              <Link to='/policy'>
                {this.props.lang.lang === 'en'
                  ? 'Privacy Policy'
                  : this.props.lang.lang === 'gr'
                  ? 'Datenschutzrichtlinie'
                  : 'سياسة الخصوصية'}
              </Link>
            </li>
            <li className='menu_item'>
              <a
                onClick={() => this.props.setLanguage('en')}
                className={this.props.lang.lang == 'en' ? 'active' : ''}
                href='#'
              >
                English
              </a>
            </li>
            <li className='menu_item'>
              <a
                onClick={() => this.props.setLanguage('ar')}
                className={this.props.lang.lang == 'en' ? 'active' : ''}
                href='#'
              >
                العربية
              </a>
            </li>
            <li className='menu_item'>
              <a
                onClick={() => this.props.setLanguage('gr')}
                className={this.props.lang.lang == 'gr' ? 'active' : ''}
                href='#'
              >
                German
              </a>
            </li>
            {Auth.getUserDetails() !== undefined &&
              Auth.getUserDetails() !== null &&
              Auth.getToken() !== undefined && (
                <>
                  <li className='menu_item'>
                    <a href='#'>
                      {this.props.lang.lang === 'en'
                        ? `Welcome ${Auth.getUserDetails().name}`
                        : this.props.lang.lang === 'gr'
                        ? 'Willkommen'
                        : `مرحباً ${Auth.getUserDetails().name}`}
                    </a>
                  </li>
                  <li className='menu_item'>
                    <Link
                      className={
                        this.props.lang.activeLink === 'profile'
                          ? 'active disabled'
                          : ''
                      }
                      to='/profile'
                    >
                      {this.props.lang.lang === 'en'
                        ? 'My Profile'
                        : this.props.lang.lang === 'gr'
                        ? 'Mein Profil'
                        : 'إعدادات الحساب'}
                    </Link>
                  </li>
                  <li className='menu_item'>
                    <a href='#' onClick={e => this.props.logout(e)}>
                      <i
                        className='fas fa-sign-in-alt mx-1'
                        aria-hidden='true'
                      />
                      {this.props.lang.lang === 'en'
                        ? 'Logout'
                        : this.props.lang.lang === 'gr'
                        ? 'Abmelden'
                        : 'تسجيل الخروج'}
                    </a>
                  </li>
                </>
              )}
            {(Auth.getUserDetails() === undefined ||
              Auth.getUserDetails() === null ||
              Auth.getToken() === undefined) && (
              <li>
                <a
                  href='#'
                  onClick={e => {
                    e.preventDefault()
                    this.props.loginClicked()
                  }}
                >
                  {this.props.lang.lang === 'en'
                    ? 'Login'
                    : this.props.lang.lang === 'gr'
                    ? 'Anmelden'
                    : 'تسجيل الدخول'}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  }
}
MobileMenu.propTypes = {
  activeClass: PropTypes.bool,
  onClose: PropTypes.func
}

export default MobileMenu
