import React, { Component } from 'react'
import SingleProduct from './SingleProduct'
import Heading from '../Heading'
import PropTypes from 'prop-types'
import CategoryService from '../../axios/CategoryService'
class NewArrivals extends Component {
  constructor (props) {
    super(props)
    this.state = {
      products: this.props.products,
      filteredProducts: this.props.products,
      searchedProducts: this.props.products,
      categories: null,
      selectedOption: 0,
      search: ''
    }
  }
  componentDidMount () {
    CategoryService.GetCategories()
      .then(res => {
        this.setState({
          categories: res
        })
      })
      .catch(err => {})
  }
  optionClicked (id) {
    if (id === 0)
      this.setState({
        filteredProducts: this.state.products,
        searchedProducts: this.state.products,
        selectedOption: id
      })
    else
      this.setState({
        filteredProducts: this.state.products.filter(e => e.category_id == id),
        searchedProducts: this.state.products.filter(e => e.category_id == id),
        selectedOption: id
      })
  }
  searchProds = _val => {
    let _prods = []
    if (_val) {
      if (this.props.lang == 'en') {
        _prods = this.state.filteredProducts
          ?.slice()
          ?.filter(e => e.en_name.toLowerCase().includes(_val.toLowerCase()))
        this.setState({ searchedProducts: _prods })
      } else {
        _prods = this.state.filteredProducts
          ?.slice()
          ?.filter(e => e.ar_name.toLowerCase().includes(_val.toLowerCase()))
        this.setState({ searchedProducts: _prods })
      }
    } else {
      let _prods = this.state.filteredProducts
      this.setState({ searchedProducts: _prods })
    }
  }
  render () {
    const { products } = this.state
    return (
      <div className='new_arrivals' data-aos='fade-up'>
        <div className='container'>
          <div className='row'>
            <Heading
              title={
                this.props.lang === 'en'
                  ? 'New Arrivals'
                  : this.props.lang === 'gr'
                  ? 'Weitere Informationen finden Sie hier'
                  : 'المنتجات المضافة حديثاً'
              }
              data-aos='fade-up'
            />
          </div>
          <div className='row mt-5 mb-1'>
            <input
              onChange={event => this.searchProds(event.target.value)}
              className='form-control'
              placeholder={
                this.props.lang === 'en'
                  ? 'Search Products....'
                  : this.props.lang === 'gr'
                  ? 'Produkte suchen....'
                  : 'بحث عن منتج...'
              }
              style={
                this.props.lang === 'en' || this.props.lang === 'gr'
                  ? { direction: 'ltr', backgroundColor: 'beige' }
                  : { direction: 'rtl', backgroundColor: 'beige' }
              }
            />
          </div>
          <div className='row align-items-center' data-aos='fade-up'>
            <div className='col text-center'>
              <div className='new_arrivals_sorting'>
                <ul className='arrivals_grid_sorting clearfix button-group filters-button-group'>
                  {(this.props.lang === 'en' || this.props.lang === 'gr') && (
                    <li
                      onClick={() => this.optionClicked(0)}
                      className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${
                        this.state.selectedOption === 0
                          ? 'active is-checked'
                          : null
                      }`}
                    >
                      {this.props.lang === 'en' ? `all` : this.props.lang === 'gr' ? "Alle" : `الكل`}
                    </li>
                  )}
                  {this.state.categories != null &&
                    this.state.categories.map(cat => {
                      return (
                        <li
                          className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${
                            this.state.selectedOption == cat.id
                              ? 'active is-checked'
                              : null
                          }`}
                          onClick={() => this.optionClicked(`${cat.id}`)}
                        >
                          {this.props.lang === 'en'
                            ? `${cat.name}`
                            : this.props.lang === 'gr'
                            ? `${cat.name_de}` 
                            : `${cat.name_a}`}
                        </li>
                      )
                    })}
                  {this.props.lang === 'ar' && (
                    <li
                      onClick={() => this.optionClicked(0)}
                      className={`grid_sorting_button button d-flex flex-column justify-content-center align-items-center ${
                        this.state.selectedOption === 0
                          ? 'active is-checked'
                          : null
                      }`}
                    >
                      {this.props.lang === 'en' ? `all` : this.props.lang === 'gr' ? "Alle" : `الكل`}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className='row'>
            {this.state.searchedProducts &&
              this.state.searchedProducts.slice(0, 8).map((item, index) => {
                return (
                  <div
                    className='col-lg-3 col-sm-6 products-container'
                    style={{ margin: '2em' }}
                    key={index}
                    data-aos='zoom-in'
                  >
                    <SingleProduct
                      lang={this.props.lang}
                      currency={this.props.currency}
                      productItem={item}
                      addToBag={this.props.addToBag}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}

NewArrivals.propTypes = {
  addToCart: PropTypes.func
}

export default NewArrivals
