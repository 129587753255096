import { connect } from "react-redux";
import Policy from "./Policy";

const mapStoreToProps = (state) => ({
  lang: state.lang,
});
const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStoreToProps, mapDispatchToProps)(Policy);
