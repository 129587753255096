import React, { Component } from 'react'
import LoginRegister from '../../components/LoginRegisterModal'
import Auth from '../../modules/Auth'
import { BASE_URL } from '../../base'
import ProductService from '../../axios/ProductService'
import { toast } from 'react-toastify'
import device, { size } from '../../modules/mediaQuery'
import MediaQuery from 'react-responsive'
import './SingleProduct.css'

class SingleProduct extends Component {
  constructor (props) {
    console.log('props, ', props)
    super(props)
    this.state = {
      color: '',
      size: '',
      pic: '',
      selectedSize: '',
      id: '',
      quantity: 0,
      modalShow: false,
      login: true,
      fav: false,
      currentImage: '',
      currentColor: '',
      currency: this.props.currency.currency,
      product: this.props.product,
      images: [],
      colors: []
    }
    this.addToFav = this.addToFav.bind(this)
    this.changeImg = this.changeImg.bind(this)
    this.changePic = this.changePic.bind(this)
  }

  getColors = () => {
    ProductService.getColors().then(resp => {
      console.log(resp.data)
      this.setState({ colors: resp.data })
    })
  }
  componentDidMount () {
    if (!this.props.product) {
      this.props.getProduct(
        this.props.location.pathname.split('/').slice(-1)[0]
      )
      this.getColors()
      setTimeout(() => {
        if (
          Auth.getUserDetails() !== undefined &&
          Auth.getUserDetails() !== null &&
          Auth.getToken() !== undefined
        ) {
          this.getIfInWishList()
        }
      }, 200)
    } else {
      this.props.getProduct(
        this.props.location.pathname.split('/').slice(-1)[0]
      )
      this.getColors()
      setTimeout(() => {
        if (
          Auth.getUserDetails() !== undefined &&
          Auth.getUserDetails() !== null &&
          Auth.getToken() !== undefined
        ) {
          this.getIfInWishList()
        }
      }, 200)
    }
    console.log(this.state.fav)
  }
  getIfInWishList () {
    ProductService.showWishlist()
      .then(resp => {
        if (resp.some(e => e.id === this.props.product.id)) {
          this.setState({ fav: true })
        }
      })
      .catch(er => console.error(er))
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.product !== this.props.product) {
      console.log('props', this.props)
      this.setState({
        product: this.props.product,
        colors: this.props.product.colors,
        currentImage: this.props.product.image,
        images: Object.keys(this.props.product)
          .map(key => {
            if (key.includes('image') && this.props.product[key]) {
              console.log(key)
              return this.props.product[key]
            }
          })
          .filter(e => !!e)
      })
    }
  }

  showHideModal = () => {
    this.setState({ modalShow: false })
  }

  loginClicked = () => {
    this.setState({ modalShow: true, login: true })
  }
  registerClicked = () => {
    this.setState({ modalShow: true, login: false })
  }

  changePic = e => {
    let colors = JSON.parse(this.props.product.color)
    let index =
      colors && colors.length > 0
        ? colors.findIndex(c => c === e.target.value)
        : 0
    index > 0
      ? this.setState({
          currentImage: this.props.product['image_' + ++index],
          currentColor: e.target.value
        })
      : this.setState({
          currentImage: this.props.product['image'],
          currentColor: e.target.value
        })
  }

  addToFav = id => {
    console.log(this.state.fav)
    if (!this.state.fav) {
      ProductService.addToFav(id)
        .then(resp => {
          this.setState({ fav: true })
          toast.success(resp)
        })
        .catch(er => console.error(er))
    } else {
      ProductService.deleteFromFav(id)
        .then(resp => {
          this.setState({ fav: false })
          toast.success(resp)
        })
        .catch(er => console.error(er))
    }
  }
  handleThumbnailClick = item => {
    this.setState({
      color: item.color,
      size: item.size,
      pic: item.imagePath,
      selectedSize: '',
      id: item._id,
      cartItem: null
    })
  }

  onAddClicked = () => {
    if (this.props.product.entity > 0) {
      if (this.state.quantity + 1 <= this.props.product.entity) {
        this.setState({ quantity: this.state.quantity + 1 })
      }
    }
  }
  onRemoveClicked = () => {
    if (this.props.product.entity > 0) {
      if (
        this.state.quantity > 0 &&
        this.state.quantity - 1 <= this.props.product.entity
      ) {
        this.setState({ quantity: this.state.quantity - 1 })
      }
    }
  }

  addToBag = () => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      const { id, selling_price, en_name } = this.props.product
      if (this.state.quantity > 0) {
        this.props.postCart(
          id,
          this.state.quantity,
          !!this.state.currentColor
            ? this.state.currentColor
            : JSON.parse(this.state.product.color)[0],
          selling_price,
          this.state.currentImage,
          en_name
        )
      } else
        toast.error(
          this.props.lang.lang === 'ar'
            ? 'يجب أن تكون الكمية أكبر من 0'
            : this.props.lang.lang === 'gr'
            ? 'Die Menge muss größer als 0 sein.'
            : 'Quantity must be greater than 0'
        )
    } else {
      this.setState({ modalShow: true })
    }
  }

  changeImg = () => {
    let images = this.state.images
    let index = images.indexOf(this.state.currentImage)
    this.setState({
      currentImage: images[index - 1 >= 0 ? index - 1 : index + 1],
      currentColor: JSON.parse(this.props.product.color)[
        index - 1 >= 0 ? index - 1 : index + 1
      ]
    })
  }

  productInCart = () => {
    let available = false
    return available
  }
  render () {
    console.log('state', this.state)
    return (
      <div className='container single_product_container'>
        {this.props.product && (
          <div>
            <div className='row prod-info'>
              <div className='col-lg-7'>
                <div className='single_product_pics'>
                  <div className='row d-flex row-arrow'>
                    <div className='col-lg-1 mt-5 btn-arrow-left'>
                      <button
                        className='btn btn-primary btn-arrow'
                        style={{ borderRadius: '20px', marginTop: '10em' }}
                        onClick={this.changeImg}
                      >
                        <i className='fa fa-arrow-left' aria-hidden='true'></i>
                      </button>
                    </div>
                    <div className='col-lg-9 image_col'>
                      <div className='single_product_image'>
                        <div
                          className='single_product_image_background'
                          style={{
                            backgroundImage: `url(${BASE_URL}upload/${this.state.currentImage})`
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-lg-1 mt-5 btn-arrow-right'>
                      <button
                        onClick={this.changeImg}
                        className='btn btn-primary'
                        style={{ borderRadius: '20px', marginTop: '10em' }}
                      >
                        <i className='fa fa-arrow-right' aria-hidden='true'></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-5 row-info'>
                <div className='product_details'>
                  <div className='product_details_title'>
                    <h2>{this.props.product.title}</h2>
                    <p>{this.props.product.description}</p>
                  </div>
                  <div className='free_delivery d-flex flex-row align-items-center justify-content-center'>
                    <span>
                      <i className='fas fa-truck'></i>
                    </span>
                  </div>

                  <div
                    className='unit_price'
                    style={
                      this.props.lang.lang === 'en' ||
                      this.props.lang.lang === 'gr'
                        ? { direction: 'ltr' }
                        : { direction: 'rtl', display: 'flex' }
                    }
                  >
                    <span
                      style={
                        this.props.lang.lang === 'ar'
                          ? {
                              direction: 'rtl',
                              float: 'right',
                              fontWeight: 'bold'
                            }
                          : { fontWeight: 'bold', direction: 'ltr' }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? ` Price:`
                        : this.props.lang.lang === 'gr'
                        ? ` Preis: `
                        : `السعر:`}
                    </span>
                    <span
                      style={
                        this.props.lang.lang === 'ar'
                          ? { float: 'right', margin: '0 1em' }
                          : {
                              margin: '0 1em'
                            }
                      }
                    >
                      {`${this.props.product.selling_price} $`}
                    </span>
                  </div>
                  <div
                    className='product_color'
                    style={
                      this.props.lang.lang === 'en' ||
                      this.props.lang.lang === 'gr'
                        ? {}
                        : { direction: 'rtl', height: '5em' }
                    }
                  >
                    <span
                      style={
                        this.props.lang.lang === 'ar'
                          ? {
                              direction: 'rtl',
                              float: 'right',
                              fontWeight: 'bold'
                            }
                          : { fontWeight: 'bold' }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? ` Color: `
                        : this.props.lang.lang === 'gr'
                        ? `Farbe: `
                        : `الألوان: `}
                    </span>
                    <select
                      onChange={e => this.changePic(e)}
                      value={this.state.currentColor}
                      className='form-control select mx-1 my-3'
                    >
                      {this.props.product.color &&
                        JSON.parse(this.props.product.color).map(e => (
                          <option
                            style={{
                              backgroundColor: e,
                              color: 'black',
                              border: '1px solid black'
                            }}
                            key={e}
                            value={e}
                          >
                            {
                              this.state.colors?.filter(c => c.code === e)[0]
                                ?.name
                            }
                          </option>
                        ))}
                    </select>
                  </div>
                  <div
                    style={
                      this.props.lang.lang === 'en' ||
                      this.props.lang.lang === 'gr'
                        ? {}
                        : { direction: 'rtl', height: '5em' }
                    }
                  >
                    <span
                      style={
                        this.props.lang.lang === 'ar'
                          ? {
                              direction: 'rtl',
                              float: 'right',
                              fontWeight: 'bold'
                            }
                          : { fontWeight: 'bold' }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? ` Description:`
                        : this.props.lang.lang === 'gr' ? 'Beschreibung:'
                        : `وصف المنتج:`}
                    </span>
                    <span style={{ margin: '0 1em' }}>
                      {this.props.lang.lang === 'en'
                        ? this.props.product.en_description
                        : this.props.lang.lang === 'gr' ? this.props.product.de_description
                        : this.props.product.ar_description}
                    </span>
                  </div>
                  <div
                    className='product_color'
                    style={
                      this.props.lang.lang === 'en' ||
                      this.props.lang.lang === 'gr'
                        ? {}
                        : {
                            direction: 'rtl',
                            height: '5em',
                            marginTop: '4em'
                          }
                    }
                  >
                    <span
                      style={
                        this.props.lang.lang === 'ar'
                          ? {
                              direction: 'rtl',
                              float: 'right',
                              margin: '0 1em',
                              fontWeight: 'bold'
                            }
                          : { fontWeight: 'bold' }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? ` Quantity In Store: (${this.props.product.entity})`
                        : this.props.lang.lang === 'gr'
                        ? `Menge: (${this.props.product.entity})`
                        : `الكمية في المستودع: (${this.props.product.entity})`}
                    </span>
                  </div>
                  <div
                    className='quantity d-flex flex-column flex-sm-row align-items-sm-center'
                    style={
                      this.props.lang.lang === 'en' ||
                      this.props.lang.lang === 'gr'
                        ? { direction: 'ltr' }
                        : { direction: 'rtl', marginTop: '5em' }
                    }
                  >
                    <span
                      style={
                        this.props.lang.lang === 'ar'
                          ? {
                              direction: 'rtl',
                              float: 'right',
                              fontWeight: 'bold',
                              textAlign: 'center'
                            }
                          : {
                              fontWeight: 'bold',
                              direction: 'ltr',
                              textAlign: 'center'
                            }
                      }
                    >
                      {this.props.lang.lang === 'en'
                        ? ` Quantity:`
                        : this.props.lang.lang === 'gr'
                        ? ` Menge:  `
                        : `الكمية:`}
                    </span>
                    <div className='quantity_selector'>
                      <span
                        className={
                          this.state.quantity > 0 ? 'minus' : 'minus disabled'
                        }
                        onClick={() => this.onRemoveClicked()}
                      >
                        <i className='fa fa-minus' aria-hidden='true'></i>
                      </span>
                      <span id='quantity_value'>{this.state.quantity}</span>
                      <span
                        className={
                          this.state.quantity === this.props.product.entity
                            ? 'plus disabled'
                            : 'plus'
                        }
                        onClick={() => this.onAddClicked()}
                      >
                        <i className='fa fa-plus' aria-hidden='true'></i>
                      </span>
                    </div>
                    <div
                      className='red_button product-add_to_cart_button'
                      onClick={this.addToBag}
                    >
                      <a href='#' onClick={e => e.preventDefault()}>
                        {this.props.lang.lang === 'en'
                          ? `add to cart`
                          : this.props.lang.lang === 'gr'
                          ? `in den Warenkorb legen`
                          : `إضافة إلى السلة`}
                      </a>
                    </div>
                    {Auth.getUserDetails() !== undefined &&
                      Auth.getUserDetails() !== null &&
                      Auth.getToken() !== undefined && (
                        <div
                          onClick={() => this.addToFav(this.props.product.id)}
                          className='product_favorite d-flex flex-column align-items-center justify-content-center'
                        >
                          <i
                            className='far fa-heart'
                            style={this.state.fav ? { color: 'red' } : {}}
                          ></i>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    )
  }
}

export default SingleProduct
