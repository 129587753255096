import React, { Component } from 'react'
import SingleProduct from '../../components/Products/SingleProduct'
import Auth from '../../modules/Auth'
import LoginRegister from '../../components/LoginRegisterModal'
import Filter from './components/Filter'
import { toast } from 'react-toastify'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom'

class WishList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      categories: this.props.categories,
      wishlist: this.props.wishlist,
      filteredProducts: this.props.wishlist,
      currency: props.currency.currency,
      modalShow: false,
      login: true,
      active: -1
    }
    this.addToBag = this.addToBag.bind(this)
    this.filterByCategory = this.filterByCategory.bind(this)
  }
  componentDidMount () {
    if (!this.props.categories) {
      this.props.getAllCategories()
    }
    if (!this.props.wichlist) {
      this.props.getWishList()
    }
  }
  showHideModal = () => {
    this.setState({ modalShow: false })
  }

  loginClicked = () => {
    this.setState({ modalShow: true, login: true })
  }
  registerClicked = () => {
    this.setState({ modalShow: true, login: false })
  }

  addToBag = (id, count, color, price, image, name) => {
    if (
      Auth.getUserDetails() !== undefined &&
      Auth.getUserDetails() !== null &&
      Auth.getToken() !== undefined
    ) {
      if (color == '')
        this.props.lang.lang === 'en'
          ? toast.error('you must select color')
          : this.props.lang.lang === 'gr'
          ? toast.error('Sie müssen eine Farbe auswählen')
          : toast.error('يجب تحديد لون المنتج الذي تريده')
      else {
        if (count > 0) {
          let cart = this.props.postCart(id, count, color, price, image, name)
          this.props.lang.lang === 'en'
            ? toast.success('Product added to cart successfully')
            : this.props.lang.lang === 'gr'
            ? toast.success('Produkt erfolgreich zum Warenkorb hinzugefügt')
            : toast.success('تم إضافة المنتج بنجاح إلى السلة')
        } else {
          toast.error(
            this.props.lang.lang === 'en'
              ? 'Quantity must be greater than 0'
              : this.props.lang.lang === 'gr'
              ? 'Die Menge muss größer als 0 sein.'
              : 'يجب أن تكون الكمية أكبر من 0'
          )
        }
      }
    } else {
      this.setState({ modalShow: true })
    }
  }
  componentDidUpdate (prevProps, prevState) {
    if (
      prevProps.categories !== this.props.categories ||
      prevProps.wishlist !== this.props.wishlist
    ) {
      this.setState({
        categories: this.props.categories.filter(c =>
          this.props.wishlist?.map(c => c.category.id).includes(c.id)
        ),
        wishlist: this.props.wishlist,
        filteredProducts: this.props.wishlist
      })
    }
  }
  filterByCategory (id) {
    if (id !== 0)
      this.setState({
        filteredProducts: this.state.wishlist.filter(e => e.category_id == id),
        active: id
      })
    else
      this.setState({
        filteredProducts: this.state.wishlist,
        active: id
      })
  }
  render () {
    const { categories, wishlist, applyFilters } = this.state
    console.log(wishlist)
    return (
      <div className='container product_section_container'>
        <div className='row'>
          <div className='col product_section clearfix'>
            <div
              className='sidebar'
              style={
                this.props.lang.lang === 'en' || this.props.lang.lang === 'gr'
                  ? { float: 'left', width: '200px', paddingRight: '17px' }
                  : {
                      float: 'right',
                      width: '200px',
                      paddingLeft: '17px'
                    }
              }
            >
              <Filter
                lang={this.props.lang.lang}
                categories={categories}
                active={this.state.active}
                filterByCategory={this.filterByCategory}
                applyFilters={applyFilters}
              />
            </div>
            <div className='main_content'>
              <div class='products_iso'>
                <div className='row'>
                  {this.state.filteredProducts &&
                    this.state.filteredProducts.map((item, index) => {
                      return (
                        <div
                          className='col-lg-3 col-sm-6'
                          key={index}
                          style={{ marginTop: '2em' }}
                          data-aos='zoom-in'
                        >
                          <SingleProduct
                            currency={this.props.currency.currency}
                            lang={this.props.lang.lang}
                            productItem={item}
                            addToBag={this.addToBag}
                          />
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginRegister
          show={this.state.modalShow}
          login={this.state.login}
          registerClicked={() => this.registerClicked()}
          loginClicked={() => this.loginClicked()}
          onHide={() => this.showHideModal()}
        />
      </div>
    )
  }
}

export default WishList
